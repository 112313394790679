import React, { useEffect } from "react";
import Grid2 from "@mui/material/Grid2";
import { storyHeader, storyBody } from "components/Layout";
import Image from "mui-image";
import image1 from "assets/images/CountryStories/Malawi/FullCircle.png";
import * as echarts from "echarts";
import mapData from "assets/data/maps/Malawi/Malawi_6_Districts_2022_Nov.json";
import "../CountryPages.scss";
import { Typography } from "@mui/material";

const Implementation = () => {
  const mapOptions = {
    title: {
      show: true,
      text: "Interactive map of Malawi",
      subtext: "Use mouse to zoom.\nHover mouse over region for tooltip.",
      left: "left",
      top: "top",
      textStyle: {
        fontSize: 20,
        fontWeight: 800,
        color: "#000000",
      },
      subtextStyle: {
        fontSize: 16,
        fontWeight: 800,
        color: "#000000",
      },
    },
    visualMap: {
      type: "piecewise",
      left: "left",
      top: "bottom",
      orient: "vertical",
      min: 0,
      max: 1,
      range: [0, 1],
      padding: 20,
      inRange: {
        color: ["#894465", "#267300"],
      },
      legend: {
        textStyle: {
          fontSize: 20,
        },
      },
      showLabel: true,
      labelLayout: { hideOverlap: true },
      align: "left",
      text: ["Districts"],
      pieces: [
        { min: 0, max: 0, label: "Non-CLM District", color: "#894465" },
        { min: 1, max: 1, label: "CLM District", color: "#267300" },
      ],
    },
    tooltip: {
      trigger: "item",
      position: "top",
      formatter: function (params) {
        return params.name;
      },
    },
    toolbox: {
      show: true,
      orient: "vertical",
      x: "right",
      y: "center",
      backgroundColor: "#ddd",
      color: "black",
      feature: {
        restore: {},
        saveAsImage: {},
      },
    },
    series: [
      {
        id: "Implementation",
        type: "map",
        roam: true,
        map: "Malawi",
        zoom: 1.25,
        aspectScale: 0.95,
        animationDurationUpdate: 1000,
        universalTransition: true,
        data: [
          {
            name: "Balaka District",
            value: 0,
          },
          { name: "Blantyre District", value: 1 },
          { name: "Chikwawa District", value: 1 },
          { name: "Chiradzulu District", value: 0 },
          { name: "Chitipa District", value: 0 },
          { name: "Dedza District", value: 0 },
          { name: "Dowa District", value: 0 },
          { name: "Karonga District", value: 0 },
          { name: "Kasungu District", value: 0 },
          { name: "Likoma District", value: 0 },
          { name: "Lilongwe District", value: 1 },
          { name: "Machinga District", value: 0 },
          { name: "Mangochi District", value: 0 },
          { name: "Mchinji District", value: 0 },
          { name: "Mulanje District", value: 0 },
          { name: "Mwanza District", value: 0 },
          { name: "Mzimba District", value: 1 },
          { name: "Neno District", value: 0 },
          { name: "Nkhata Bay District", value: 0 },
          { name: "Nkhotakota District", value: 0 },
          { name: "Nsanje District", value: 0 },
          { name: "Ntcheu District", value: 0 },
          { name: "Ntchisi District", value: 0 },
          { name: "Phalombe District", value: 0 },
          { name: "Rumphi District", value: 0 },
          { name: "Salima District", value: 0 },
          { name: "Thyolo District", value: 1 },
          { name: "Zomba District", value: 1 },
        ],
      },
    ],
  };

  useEffect(() => {
    echarts.registerMap("Malawi", mapData, {});

    const theMap = echarts.init(document.getElementById("themap"));
    theMap.setOption(mapOptions, true);
  }, []);

  return (
    <Grid2
      container
      id="implementation"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>Malawi CLM Implementation Program</header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2
            container
            sx={{
              mt: "1vh",
              height: "70vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid2 size={{ sm: 12, md: 12, lg: 6, curved: 7 }} tabIndex={0}>
              <Grid2 size={12}>
                <div
                  role="presentation"
                  aria-label="interactive map"
                  id="themap"
                  className="daa-map-container"
                ></div>
              </Grid2>
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", padding: "0 2vw 0 2vw" }}
                >
                  Map showing the location of the 6 districts where CLM is
                  implemented by MANASO.
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2 contaier size={{ sm: 12, md: 12, lg: 6, curved: 5 }}>
              <Grid2 size={12}>
                <div className="image-frame">
                  <Image
                    tabIndex={0}
                    fit={"contain"}
                    sx={{
                      height: "65vh !important",
                    }}
                    src={image1}
                    alt="A diagram of the cycle of implementation of Community Led Monitoring."
                  />
                </div>
              </Grid2>
              <Grid2 size={12}>
                <Typography
                  tabIndex={0}
                  variant="body2"
                  style={{ textAlign: "center" }}
                >
                  CLM Implementation Cycle of activities from data collection,
                  analysis and translation, engagement and dissemination,
                  advocacy, and monitoring. The cycle then repeats.
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default Implementation;
