import React from "react";
import Grid from "@mui/material/Grid2";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { storyHeader, storyBody } from "components/Layout";
import Image from "mui-image";
import image1 from "assets/images/CountryStories/CDI/Timeline.png";
import "../CountryPages.scss";

const Timeline = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="timeline"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title cdi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>Community-Led Monitoring (CLM) Timeline</header>
        </Grid>
        <Grid container size={12} sx={storyBody}>
          <Grid size={12}>
            <Typography
              tabIndex={0}
              variant="h2"
              sx={{
                color: theme.color,
                padding: "1vh 5vw",
                textAlign: "center",
              }}
            >
              <font style={{ color: theme.cdiHilite2 }}>
                Community-Led Organizations (CLOs)
              </font>{" "}
              engage to remove barriers facing PLHIV to
              <br />
              improve Côte d’Ivoire’s response to reach HIV 95-95-95.
            </Typography>
          </Grid>
          <Grid size={12}>
            <div className="image-frame">
              <Image
                tabIndex={0}
                src={image1}
                fit="contain"
                style={{ width: "90%" }}
                alt="Timeline depicting the background and influencing factors that led to the implementation of a harmonized and structured Community-Led Monitoring (CLM) program in Cote d'Ivoire. Highlights: in 2008 the MSHPCMU (Ministry of Health in Cote d'Ivoire) issues a notice mandating free HIV services; in 2019 Community-led organizations (CLOs) voiced that PLHIV were continuing to face user fees when trying to access HIV services; MSHPCMU issues a new circular in the same year to reinforce HIV services are free; in 2021 PEPFAR commits funds to initiate CLM in Cote d'Ivoire and CLOs come together to develop a shared vision for CLM with the support of UNAIDS, PEPFAR, the Global Fund, and MSHPCMU; in 2021, PEPFAR Cote d'Ivoire starts to directly fund CLOs to implement a structured CLM program through the CDC's Cooperative Agreement with UNAIDS, which also includes a capacity building component to strengthen CLOs and drive necessary changes in Cote d'Ivoire."
              />
            </div>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Timeline;
