import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CountryModal from "./CountryModal";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import { selectapp, updateappState } from "stateMgmt/appSlice";

import "./CountryStoryMain.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CountryModalContainer = () => {
  const [open, setOpen] = React.useState(false);
  const appState = useSelector(selectapp);
  const dispatch = useDispatch();
  const theme = useSelector(selectapp).theme;

  const handleClose = () => {
    dispatch(updateappState({ context: "home" }));
  };

  useEffect(() => {
    setOpen(appState.context === "countries");
  }, [appState.context]);

  return (
    <div className="story-swiper-container">
      <Dialog
        id="story-modal"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100vw",
              maxWidth: "100%", // Set your width here
              maxHeight: "100vh",
              padding: "2px",
              margin: "0px",
              overflow: "hidden",
            },
          },
        }}
        aria-label="Country stories slide show"
        backdrop={"true"}
        role="dialog"
        name="story-modal"
        aria-modal="true"
        aria-hidden={false}
        tabIndex={"-1"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <div className="stories-modal-header">
            <div>
              <Typography variant="body1">
                <a
                  id="acronyms"
                  href="/acronyms.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  tabIndex={0}
                  name="acronyms"
                  role="navigation"
                  aria-label="Click here for the Glossary of Acronyms"
                  style={{ color: theme.linkColor }}
                >
                  Click here for the Glossary of Acronyms
                </a>
              </Typography>
            </div>
            <div>
              <Typography variant="body1">
                <a
                  id="back-to-spotlight"
                  onClick={() => handleClose()}
                  tabIndex={0}
                  name="return"
                  role="navigation"
                  aria-label="Click here to Navigate back to Spotlight"
                  style={{ color: theme.linkColor, cursor: "pointer" }}
                >
                  Click here to Navigate back to Spotlight
                </a>
              </Typography>
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <CountryModal />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CountryModalContainer;
