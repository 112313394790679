import React from "react";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Image from "mui-image";
import { storyHeader, storyBody } from "components/Layout";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import heart from "assets/images/CountryStories/CDI/heart.png";
import redCircle from "assets/images/CountryStories/CDI/redCircle.png";
import purpleCircle from "assets/images/CountryStories/CDI/purpleCircle.png";
import greenCircle from "assets/images/CountryStories/CDI/greenCircle.png";
import blueCircle from "assets/images/CountryStories/CDI/blueCircle.png";
import "../CountryPages.scss";

const TheHeartOne = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid2
      container
      id="theHeartOne"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            CLM : Community at the Heart &quot;Cœur&quot; of a Multi-Stakeholder
            Effort
          </header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2 container sx={{ height: "75vh" }}>
            <Grid2 container size={12} sx={{ height: "19vh" }}>
              <Grid2 container size={4}>
                <Grid2 item size={12}>
                  <Typography tabIndex={0} variant="h1" sx={{ pt: "5vh" }}>
                    Key Stakeholders and their Roles
                  </Typography>
                </Grid2>

                <Grid2 item size={12} sx={{pl: 2, pr: 3}}>
                  <List
                    sx={{
                      lineHeight: 1.0,
                      pl: 1,
                      color: theme.color,
                      listStyleType: "none",
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Build communities’ consensus on CLM priorities and
                        sustain progress
                      </Typography>
                    </ListItem>
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Lead the full CLM cycle
                      </Typography>
                    </ListItem>
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Proactively engage stakeholders to improve services
                        based on data
                      </Typography>
                    </ListItem>
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Establish approaches to document lessons learned, best
                        practices, and outcomes
                      </Typography>
                    </ListItem>
                  </List>
                </Grid2>
              </Grid2>

              <Grid2 item size={2} sx={{ height: "22vh" }}>
                <Image
                  className="icon-frame"
                  src={redCircle}
                  fit="contain"
                  alt=""
                />
              </Grid2>
              <Grid2 item size={6} sx={{ pt: 1 }}>
                <List
                  sx={{
                    lineHeight: 1.0,
                    borderLeft: "3px solid #B53023",
                    pl: 1,
                    color: theme.color,
                    listStyleType: "none",
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Champion uptake of CLM models
                    </Typography>
                  </ListItem>
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Enable multi-stakeholder dialogue, partnerships, and
                      coordination
                    </Typography>
                  </ListItem>
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Guide the structure and coordination of community
                      leadership in CLM
                    </Typography>
                  </ListItem>
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Transferring Resources, Monitoring and Building CLO
                      Capacity
                    </Typography>
                  </ListItem>
                </List>
              </Grid2>
            </Grid2>
            <Grid2 container size={12} sx={{ height: "22vh" }}>
              <Grid2 container size={6} sx={{ height: "22vh" }}>
                <Grid2 item size={7}></Grid2>
                <Grid2 item size={5}>
                  <Image
                    className="icon-frame"
                    style={{ maxHeight: "30vh" }}
                    src={heart}
                    fit="contain"
                    alt=""
                  />
                </Grid2>
              </Grid2>
              <Grid2 container size={6}>
                <Grid2 item size={3} sx={{ height: "22vh" }}>
                  <Image
                    className="icon-frame"
                    src={greenCircle}
                    fit="contain"
                    alt=""
                  />
                </Grid2>
                <Grid2 item size={9} sx={{ pt: 1 }}>
                  <List
                    sx={{
                      lineHeight: 1.0,
                      borderLeft: "3px solid #20650B",
                      pl: 1,
                      color: theme.color,
                      listStyleType: "none",
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Foster an enabling CLM operating environment
                      </Typography>
                    </ListItem>
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Facilitate access to health facilities for data
                        collection
                      </Typography>
                    </ListItem>
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Encourage space to discuss CLM data and link to
                        decisions
                      </Typography>
                    </ListItem>
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Use generated evidence for education, advocacy and
                        engagement
                      </Typography>
                    </ListItem>
                  </List>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 container size={12} sx={{ height: "19vh" }}>
              <Grid2 item size={2} sx={{ height: "22vh" }}>
                <Image
                  className="icon-frame"
                  src={purpleCircle}
                  fit="contain"
                  alt=""
                />
              </Grid2>
              <Grid2 item size={5}>
                <List
                  sx={{
                    lineHeight: 1.0,
                    borderLeft: "3px solid #6632C2",
                    pl: 1,
                    pt: 3,
                    color: theme.color,
                    listStyleType: "none",
                    "& .MuiListItem-root": {
                      display: "list-item",
                    },
                  }}
                >
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Building the capacity of multiple stakeholders in the CLM
                      cycle
                    </Typography>
                  </ListItem>
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Assist with design, validation of tools/indicators and
                      data quality audit
                    </Typography>
                  </ListItem>
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Support data analysis and advocacy strategies
                    </Typography>
                  </ListItem>
                  <ListItem tabIndex={0}>
                    <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                      Foster horizontal learning and local CLM capacity for
                      sustainability
                    </Typography>
                  </ListItem>
                </List>
              </Grid2>
              <Grid2 container size={5}>
                <Grid2 item size={4} sx={{ height: "22vh" }}>
                  <Image
                    className="icon-frame"
                    src={blueCircle}
                    fit="contain"
                    alt=""
                  />
                </Grid2>
                <Grid2 item size={8} sx={{ pt: 4 }}>
                  <List
                    sx={{
                      lineHeight: 1.0,
                      borderLeft: "3px solid #122037",
                      pl: 1,
                      color: theme.color,
                      listStyleType: "none",
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem tabIndex={0}>
                      <Typography sx={{ px: 0, mx: 0 }} variant="body2">
                        Provide catalytic funding aligned with country
                        priorities and modalities that enable CLM sustainability
                      </Typography>
                    </ListItem>
                  </List>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default TheHeartOne;
