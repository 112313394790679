import React from "react";
import Grid2 from "@mui/material/Grid2";
import Image from "mui-image";
import Typography from "@mui/material/Typography";
import { storyHeader, storyBody } from "components/Layout";
import timeline from "assets/images/CountryStories/CDI/Timeline2.png";
import msg1 from "assets/images/CountryStories/CDI/ApproachMsg1.png";
import msg2 from "assets/images/CountryStories/CDI/ApproachMsg2.png";
import msg3 from "assets/images/CountryStories/CDI/ApproachMsg3.png";
import msg4 from "assets/images/CountryStories/CDI/ApproachMsg4.png";
import msg5 from "assets/images/CountryStories/CDI/ApproachMsg5.png";
import msg6 from "assets/images/CountryStories/CDI/ApproachMsg6.png";
import "../CountryPages.scss";

const InAction = () => {
  const boxFormat = {
    py: 1,
    px: 1,
    backgroundColor: "#D9D9D9",
    border: "3px solid #bbbbbb",
    borderRadius: 3,
  };

  return (
    <Grid2
      container
      id="inAction"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>CLM : The Approach in Action</header>
        </Grid2>
        <Grid2 container size={12} sx={storyBody}>
          <Grid2
            container
            size={12}
            spacing={1}
            sx={{ height: "55vh", pt: 2, px: "1.5vw" }}
          >
            <Grid2 container size={2} sx={boxFormat}>
              <Grid2 size={2}>
                <div>
                  <Image
                    src={msg1}
                    style={{ width: "1.0vw" }}
                    fit="contain"
                    tabIndex={0}
                    alt="Indentify issues."
                  />
                </div>
              </Grid2>
              <Grid2 size={10} sx={{ backgroundColor: "#D9D9D9" }}>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1, fontWeight: 700 }}
                    variant="body2"
                  >
                    Examples:
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Long wait times for beneficiaries receiving services
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Insufficient promotion of PrEP for HIV prevention
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Self-stigmatization among PLHIV & KP
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 container size={2} sx={boxFormat}>
              <Grid2 size={2}>
                <div>
                  <Image
                    src={msg2}
                    tabIndex={0}
                    style={{ width: "1.0vw" }}
                    fit="contain"
                    alt="Collect information."
                  />
                </div>
              </Grid2>
              <Grid2 size={10} sx={{ backgroundColor: "#D9D9D9" }}>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1, fontWeight: 700 }}
                    variant="body2"
                  >
                    <strong>3,856</strong> interviews:
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>2,427</strong> PLHIV (63%)
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>611</strong> KP (16%)
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>284</strong> health care workers (7%)
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>534</strong> general population (14%)
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>50</strong> community dialogues
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 container size={2} sx={boxFormat}>
              <Grid2 size={2}>
                <div>
                  <Image
                    src={msg3}
                    tabIndex={0}
                    style={{ width: "1.0vw" }}
                    fit="contain"
                    alt="Analyze"
                  />
                </div>
              </Grid2>
              <Grid2 size={10} sx={{ backgroundColor: "#D9D9D9" }}>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1, fontWeight: 700 }}
                    variant="body2"
                  >
                    Examples:
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>52%</strong> of PLHIV & <strong>76%</strong> of KP
                    wait 30 minutes or longer for services
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>80%</strong> of PLHIV in serodiscordant couples &{" "}
                    <strong>41%</strong> of KP do not have info on PrEP
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    <strong>38%</strong> of PLHIV & <strong>32%</strong> of KP
                    mention feeling self-stigmatization related to mental health
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 container size={2} sx={boxFormat}>
              <Grid2 size={2}>
                <div>
                  <Image
                    src={msg4}
                    tabIndex={0}
                    style={{ width: "1.0vw" }}
                    fit="contain"
                    alt="Advocacy strategy."
                  />
                </div>
              </Grid2>
              <Grid2 size={10} sx={{ backgroundColor: "#D9D9D9" }}>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1, fontWeight: 700 }}
                    variant="body2"
                  >
                    Examples:
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Launch an audit mission to optimize the patient health care
                    circuit
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Enhance the role of community players and Strengthen HIV
                    communication strategies
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Intensify the training of healthcare professionals
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 container size={2} sx={boxFormat}>
              <Grid2 size={2}>
                <div>
                  <Image
                    src={msg5}
                    tabIndex={0}
                    style={{ width: "1.0vw" }}
                    fit="contain"
                    alt="Advocacy"
                  />
                </div>
              </Grid2>
              <Grid2 size={10} sx={{ backgroundColor: "#D9D9D9" }}>
                <Grid2>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  ></Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    CLOs led presentations at various health and administrative
                    level in Côte d’Ivoire
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Developed advocacy plans for 21 health districts to address
                    specific problems
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    Started implementing these plans through local and regional
                    advocacy taskforces
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 container size={2} sx={boxFormat}>
              <Grid2 size={2}>
                <div>
                  <Image
                    src={msg6}
                    tabIndex={0}
                    style={{ width: "1.0vw" }}
                    fit="contain"
                    alt="Monitor the change."
                  />
                </div>
              </Grid2>
              <Grid2 size={10} sx={{ backgroundColor: "#D9D9D9" }}>
                <Grid2>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  ></Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    CLOs remain engaged with taskforces
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    CLOs continue to receive capacity building, including 2
                    additional CLOs
                  </Typography>
                </Grid2>
                <Grid2 tabIndex={0}>
                  <Typography
                    sx={{ color: "#000000", px: 0, mb: 1 }}
                    variant="body2"
                  >
                    A second round of data collection will be conducted in the
                    coming year to measure results and support further advocacy
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 size={12}>
              <div className="image-frame">
                <Image src={timeline} fit="contain" style={{ width: "80vw" }} />
              </div>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default InAction;
