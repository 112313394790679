import React from "react";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Image from "mui-image";
import image1 from "assets/images/CountryStories/Dreams/results.png";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";
import { Typography } from "@mui/material";

const Results = () => {
  const barContent = {
    display: "block",
    overflow: "auto",
    px: "2vw",
    py: "2vh",
    minHeight: "30vh",
    overflowWrap: "break-word",
    color: "#ffffff",
    backgroundColor: "#002060",
    border: "2px solid gray",
    borderRadius: 4,
  };

  return (
    <Grid2
      container
      id="results"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>DREAMS NextGen 2023 Results</header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2 container size={12} spacing={2} sx={{ px: "5vw" }}>
            <Grid2 size={12}>
              <Typography
                tabIndex={0}
                variant="h1"
                sx={{
                  width: "80vw",
                  textAlign: "center",
                  mx: "auto",
                  mb: "4vh",
                }}
              >
                Over 2.5 million AGYW reached by DREAMS in the last fiscal year
              </Typography>
            </Grid2>
            <Grid2
              container
              size={7}
              direction="row"
              spacing={2}
              style={{ display: "flex" }}
            >
              <Grid2 item size={6} tabIndex={0}>
                <Box sx={barContent}>
                  <Typography variant="h4">
                    More than <span style={{ color: "#FE0000" }}>490,000</span>{" "}
                    were supported to stay in school.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 item size={6} tabIndex={0}>
                <Box sx={barContent}>
                  <Typography variant="h4">
                    Approximately{" "}
                    <span style={{ color: "#FE0000" }}>2 million</span> 
                    received an HIV and violence prevention curriculum.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 item size={6} tabIndex={0}>
                <Box sx={barContent}>
                  <Typography variant="h4">
                    Over <span style={{ color: "#FE0000" }}>250,000</span>{" "}
                    received enhanced economic empowerment  through skills
                    training and bridge to employment.
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 item size={6} tabIndex={0}>
                <Box sx={barContent}>
                  <Typography variant="h4">
                    Over 
                    <span style={{ color: "#FE0000" }}>1.8 million</span>{" "}
                    completed at least the primary package of DREAMS services.
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
            <Grid2 container size={5}>
              <Grid2 size={12} container>
                <div className="image-frame">
                  <Image
                    tabIndex={0}
                    style={{ maxHeight: "60vh" }}
                    fit="contain"
                    src={image1}
                    alt="Decorative image."
                  />
                </div>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 container size={6}></Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default Results;
