// Place common Javascript utilities/functions here to be used across whole website
// import logger from "services/LoggingService";
import { isMobileOnly, isTablet } from "react-device-detect";

const smallBreakpoint = 1024;
const xlBreakpoint = 3800;
const largeBreakpoint = 1900;
const hugeBreakpoint = 2880;

// const tabletBreakpoint = 1024
export const iPadDimensions = { width: 768, height: 1024 };

export function getFileExtension(fileName) {
  const a = fileName.split(".");
  if (a.length === 1 || (a[0] === "" && a.length === 2)) {
    return "";
  }

  return a.pop();
}

export function isBrowserIE11() {
  // Detect if browser is IE11
  const userAgent = window.navigator.userAgent;
  const msie = userAgent.indexOf("Trident/");
  if (msie > 0) {
    return true;
  }
  return false;
}

export function useMicrostrategyDev() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "data-dev.pepfar.gov" ||
    window.location.hostname === "data-staging.pepfar.gov" ||
    window.location.hostname === "data-dev-2.pepfar.gov"
  ) {
    return true;
  }
  return false;
}

export function getMicroStrategyUrl() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "data-dev.pepfar.gov" ||
    window.location.hostname === "data-dev-2.pepfar.gov"
  ) {
    return "devURL";
  } else if (window.location.hostname === "data-staging.pepfar.gov") {
    return "stagingURL";
  }
  return "prodURL";
}

export function getS3Url() {
  const host = window.location.hostname;
  switch (host) {
    case "localhost":
    case "data-dev.pepfar.gov":
    case "data-dev-2.pepfar.gov":
      return "https://s3.amazonaws.com/media.data-dev.pepfar.gov/";

    case "data-staging.pepfar.gov":
      return "https://s3.amazonaws.com/media.data-staging.pepfar.gov/";

    case "data.pepfar.gov":
      return "https://s3.amazonaws.com/media.data.pepfar.gov/";
  }
}

export function objCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getChartFontSize() {
  let fontSize;
  if (isMobileOnly || isTablet) {
    fontSize = 9;
  } else if (isXL()) {
    fontSize = 24;
  } else if (isHuge()) {
    fontSize = 24;
  } else if (isLarge()) {
    fontSize = 20;
  } else {
    fontSize = 16;
  }
  return fontSize;
}

export function getChartTitleSize() {
  return getChartFontSize() + 2;
}

export function isLandscape() {
  const dim = getWindowDimensions();
  return dim.width > dim.height;
}

export function isPortrait() {
  const dim = getWindowDimensions();
  return dim.height > dim.width;
}

export function isMobile() {
  return isMobileOnly;
}

// export function isTablet() {
//   return isTablet;
// }

export function isSmall() {
  const dim = getWindowDimensions();
  return dim.width <= smallBreakpoint;
}

export function isLarge() {
  const dim = getWindowDimensions();
  return dim.width >= largeBreakpoint && dim.width < xlBreakpoint;
}

export function isXL() {
  const dim = getWindowDimensions();
  return dim.width >= xlBreakpoint && dim.width < hugeBreakpoint;
}

export function isHuge() {
  const dim = getWindowDimensions();
  return dim.width >= hugeBreakpoint;
}

export function getScreenCenter(windowDimensions) {
  return {
    width: windowDimensions.width / 2,
    height: windowDimensions.height / 2,
  };
}

export function runningJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}

export function checkCache() {
  return !runningJest(); // && process.env.NODE_ENV === 'production'
}

export const fetchTableData = (dataIn) => {
  const memData = JSON.parse(sessionStorage.getItem("currentDAATableRowData"));
  let data = [];

  if (memData === null || memData.length === 0) {
    dataIn.forEach((row) => {
      if (row.properties.tracked) {
        data.push(row);
      }
    });
  } else {
    data = [...memData];
  }
  sessionStorage.setItem("currentDAATableRowData", JSON.stringify(data));
  return data;
};

export const roundNumber = (input, decimals) => {
  let retVal = 0;
  try {
    retVal = decimals === undefined ? input.toFixed() : input.toFixed(decimals);
  } catch (e) {
    console.log(`roundNumber error converting ${input}`);
  }
  return retVal;
};

export const thousandsSep = (input) => {
  return new Intl.NumberFormat("en-US").format(input);
};
