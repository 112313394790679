import React from "react";
import Grid2 from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image from "assets/images/CountryStories/Malawi/remediation.png";

import "../CountryPages.scss";

const Remediation = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid2
      container
      id="remediation"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>The Remediation Process</header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2
            container
            sx={{
              mt: "1vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid2 size={{ sm: 12, md: 12, lg: 6, xl: 5, curved: 7 }}>
              <List
                sx={{
                  pl: 5,
                  color: theme.color,
                  listStyleType: "none",
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    In August 2023, the CLM team brought the matter to the
                    attention of facility-level providers and PEPFAR
                    implementing partners.
                  </Typography>
                </ListItem>
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    The CLM team and service providers together conducted a root
                    cause analysis to identify the cause of the problem.
                  </Typography>
                </ListItem>
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    It was established that the long waiting hours were due to
                    inadequate staff at the facility.
                  </Typography>
                </ListItem>
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    At the time of data collection, the facility had only one
                    HIV Diagnostic Assistant (HDA) responsible for HIV testing,
                    counselling, and collecting viral load samples.
                  </Typography>
                </ListItem>
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    This led to extended waiting times for the recipients of
                    care.
                  </Typography>{" "}
                   
                </ListItem>
              </List>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 12, lg: 6, xl: 7, curved: 5 }}>
              <Grid2 size={12}>
                <div className="image-frame">
                  <Image
                    tabIndex={0}
                    sx={{
                      height: "65vh !important",
                    }}
                    fit={"contain"}
                    src={image}
                    alt="Picture 4 depicts a district level engagement meeting involving key stakeholders responsible for decision making on health matters in Thyolo district."
                  />
                </div>
              </Grid2>
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", padding: "0 2vw 0 2vw" }}
                >
                  A district level engagement meeting involving key stakeholders
                  responsible for decision making on health matters in Thyolo
                  district
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default Remediation;
