import React from "react";
import Grid2 from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import Image from "mui-image";
import image1 from "assets/images/CountryStories/VietNam/feedback.png";
import "../CountryPages.scss";

const Feedback = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid2
      container
      id="feedback"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Community Feedback for Improved Service Quality and Access
          </header>
        </Grid2>
        <Grid2 container size={12} sx={storyBody}>
          <Grid2 size={7}>
            <List
              sx={{
                pl: "10vw",
                pr: "5vw",
                pt: 0,
                color: theme.color,
                listStyleType: "none",
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem tabIndex={0}>
                <Typography variant="body1">
                  Clients at PEPFAR-supported sites or participating in
                  PEPFAR-supported community activities can provide their
                  feedback of their service experience via different platforms,
                  such as scorecards, QR codes, or consultation sessions with
                  health staff.
                </Typography>
              </ListItem>
              <ListItem tabIndex={0}>
                <Typography variant="body1">
                  Results are routinely consolidated, reviewed and used to
                  improve services.
                </Typography>
              </ListItem>
              <ListItem tabIndex={0}>
                <Typography variant="body1">
                  Clients also provide feedback and raise concerns about
                  above-site barriers (e.g., social health insurance procedures,
                  service delivery procedures, drug supply).
                </Typography>
              </ListItem>
              <ListItem tabIndex={0}>
                <Typography variant="body1">
                  A community core team working on community-led monitoring
                  captures these issues and brings them to quarterly dialogues
                  with stakeholders, including national and provincial
                  government offices, donors, development partners, and
                  implementing partners.
                </Typography>
              </ListItem>
            </List>
          </Grid2>
          <Grid2 container size={5}>
            <Grid2 sx={{ pt: 5 }}>
              <div style={{ width: "auto", height: "50vh" }}>
                <Image
                  tabIndex={0}
                  fit={"contain"}
                  src={image1}
                  alt="QR code that clients can use to provide feedback about service quality and share concerns about barriers. Score-card that clients can use to provide feedback about service quality and share concerns about barriers"
                />
              </div>
            </Grid2>
            <Grid2>
              <Typography variant="h3" tabIndex={0}>
                Score-card and QR code that clients can use to provide feedback
                about service quality and share concerns about barriers.
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default Feedback;
