import React from "react";
import { createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import { v4 as uuid } from "uuid";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      ultra: 2800,
      dense: 3800,
      curved: 4500,
    },
  },
  typography: {
    htmlFontSize: 6,
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: 700,
    marginBottom: ".5rem",
    lineHeight: 1.2,
  },
});

theme.typography.h1 = {
  paddingLeft: "10px",
  fontSize: "1.3rem",
  fontWeight: 800,
  "@media (min-width:600px)": {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.up("curved")]: {
    fontSize: "4.0vmin",
    // color: "yellow",
  },
  [theme.breakpoints.between("dense", "curved")]: {
    fontSize: "2.5vmin",
    // color: "cyan",
  },
  [theme.breakpoints.between("ultra", "dense")]: {
    fontSize: "2.5vmin",
    // color: "purple",
  },
  [theme.breakpoints.between("xl", "ultra")]: {
    fontSize: "3.5vmin",
    // color: "red",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "3.5vmin",
    // color: "blue",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.5rem",
    // color: "green",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
    // color: "orange",
  },
};

theme.typography.h2 = {
  paddingTop: "10px",
  paddingLeft: "10px",
  fontSize: "0.8rem",
  fontWeight: 700,
  "@media (min-width:600px)": {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.3rem",
  },
};

theme.typography.h3 = {
  paddingLeft: "10px",
  fontSize: "0.4rem",
  fontWeight: 700,

  "@media (min-width:600px)": {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.up("curved")]: {
    fontSize: "4.0vmin",
    // color: "yellow",
  },
  [theme.breakpoints.between("dense", "curved")]: {
    fontSize: "2.5vmin",
    // color: "cyan",
  },
  [theme.breakpoints.between("ultra", "dense")]: {
    fontSize: "2.3vmin",
    // color: "purple",
  },
  [theme.breakpoints.between("xl", "ultra")]: {
    fontSize: "2.5vmin",
    // color: "red",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "2.3vmin",
    // color: "blue",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.9rem",
    // color: "green",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
    // color: "orange",
  },
};

theme.typography.h4 = {
  paddingLeft: "10px",
  fontSize: "0.4rem",
  fontWeight: 700,
  lineHeight: "1.5",

  "@media (min-width:600px)": {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.5vmax",
    // color: "green",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "1.3vmax",
    // color: "red",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.9rem",
    // color: "yellow",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
    // color: "blue",
  },
};

theme.typography.h5 = {
  paddingLeft: "10px",
  fontSize: "0.4rem",
  fontWeight: 700,

  "@media (min-width:600px)": {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "1.0vmax",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.h6 = {
  fontSize: "0.1rem",
  fontWeight: 700,
  "@media (min-width:600px)": {
    fontSize: "0.2rem",
  },
  [theme.breakpoints.up("curved")]: {
    fontSize: "2.4vmin",
    // color: "yellow",
  },
  [theme.breakpoints.between("dense", "curved")]: {
    fontSize: "1.5vmin",
    // color: "cyan",
  },
  [theme.breakpoints.between("ultra", "dense")]: {
    fontSize: "1.5vmin",
    // color: "purple",
  },
  [theme.breakpoints.between("xl", "ultra")]: {
    fontSize: "1.5vmin",
    // color: "red",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "1.5vmin",
    // color: "blue",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "0.9rem",
    // color: "green",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
    // color: "orange",
  },
};

theme.typography.body1 = {
  fontSize: "0.4rem",
  paddingTop: "15px",
  paddingLeft: "30px",

  "@media (max-width:768px)": {
    fontSize: "0.8rem",
    // color: "pink",
  },
  [theme.breakpoints.up("curved")]: {
    fontSize: "4.0vmin",
    // color: "yellow",
  },
  [theme.breakpoints.between("ultra", "curved")]: {
    fontSize: "2.5vmin",
    // color: "purple",
  },
  [theme.breakpoints.between("xl", "ultra")]: {
    fontSize: "2.5vmin",
    // color: "red",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "2.5vmin",
    // color: "blue",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.2rem",
    // color: "green",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
    // color: "orange",
  },
};

theme.typography.body2 = {
  fontSize: "0.4rem",
  paddingLeft: "5px",
  "@media (max-width:768px)": {
    fontSize: "0.8rem",
    // color: "pink",
  },
  [theme.breakpoints.up("curved")]: {
    fontSize: "2.3vmin",
    // color: "yellow",
  },
  [theme.breakpoints.between("ultra", "curved")]: {
    fontSize: "2.3vmin",
    // color: "purple",
  },
  [theme.breakpoints.between("xl", "ultra")]: {
    fontSize: "2.3vmin",
    // color: "red",
  },
  [theme.breakpoints.between("lg", "xl")]: {
    fontSize: "2.1vmin",
    // color: "blue",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "1.2rem",
    // color: "green",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
    // color: "orange",
  },
};

export const getSpotlightTheme = () => {
  return theme;
};

export const createThemeLink = (url, text, linkColor, blurFunc) => {
  return url.includes("http") ? (
    <Link
      underline="hover"
      key={uuid()}
      // sx={{ color: linkColor, fontWeight: 500 }}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Typography variant="h3">{text}</Typography>
    </Link>
  ) : (
    <Link
      underline="hover"
      key={uuid()}
      // sx={{ color: linkColor, fontWeight: 500 }}
      href={url}
      onBlur={blurFunc}
    >
      <Typography variant="h3">{text}</Typography>
    </Link>
  );
};

export const createSidebarLink = (url, text, linkColor, blurFunc) => {
  const newLink = createThemeLink(url, text, linkColor, blurFunc);
  return <ListItem tabIndex={0}>{newLink}</ListItem>;
};
