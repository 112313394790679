import React from "react";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image1 from "assets/images/CountryStories/VietNam/realTime.png";
import "../CountryPages.scss";

const RealTimeTwo = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid2
      container
      id="realTimeTwo"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Optimizing Real-Time Data for Effective Community Outreach &
            Referrals
          </header>
        </Grid2>
        <Grid2 container size={12} sx={storyBody}>
          <Grid2 container size={12} columnGap={2} sx={{ mx: "2vw" }}>
            <Grid2 size={12} sx={{ m: 0, p: 0 }}>
              <Typography
                tabIndex={0}
                variant="h2"
                style={{ height: "5vh" }}
                sx={{ color: theme.color, mt: 2, mb: 0, p: 0 }}
              >
                After system interoperability intervention (Oct 2022):
              </Typography>
            </Grid2>
            <Grid2
              container
              size={6}
              style={{ height: "60vh" }}
              sx={{ m: 0, p: 0 }}
            >
              <Grid2 size={12} item>
                <Typography
                  tabIndex={0}
                  variant="body1"
                  sx={{ color: theme.color }}
                >
                  <strong>QR code referrals</strong> between community and
                  facilities
                </Typography>
              </Grid2>

              <Grid2 size={12} item>
                <Typography
                  tabIndex={0}
                  variant="body1"
                  sx={{ color: theme.color }}
                >
                  <strong>Automatic Data Sync</strong> from community apps to
                  facilities software
                </Typography>
              </Grid2>

              <Grid2 size={12} item>
                <Typography
                  tabIndex={0}
                  variant="body1"
                  sx={{ color: theme.color }}
                >
                  <strong>Track Referral Success</strong> in real-time
                </Typography>
              </Grid2>

              <Grid2 size={12} item>
                <Typography
                  tabIndex={0}
                  variant="body1"
                  sx={{ color: theme.color }}
                >
                  <strong>Data deduplication</strong> via unique client code
                </Typography>
              </Grid2>

              <Grid2 size={12} item>
                <Typography
                  tabIndex={0}
                  variant="body1"
                  sx={{ color: theme.color }}
                >
                  <strong>Standardized Community Data</strong> and Forms across
                  community systems
                </Typography>
              </Grid2>

              <Grid2 size={12} item>
                <Typography
                  tabIndex={0}
                  variant="body1"
                  sx={{ color: theme.color }}
                >
                  <strong>Client&apos;s Status Update</strong> (HIV confirmation
                  test, ARV/PrEP) being notified back to outreach workers
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2
              container
              size={5}
              style={{
                height: "40vh",
              }}
              sx={{ m: 0, p: 0 }}
            >
              <div className="image-frame-top">
                <Image
                  tabIndex={0}
                  style={{ borderRadius: 16, height: "30vh" }}
                  src={image1}
                  alt="This diagram shows Ho Chi Minh City's interoperability model, where outreach data flows into a data lake and syncs in real-time with referral service systems."
                />
              </div>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                tabIndex={0}
                variant="body1"
                sx={{
                  color: theme.vietnamHilite2,
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Improvement after data integration intervention:
                <br />
                94% of client records matched between community and facilities
                databases
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default RealTimeTwo;
