import React from "react";
import Grid from "@mui/material/Grid2";
import Image from "mui-image";
import image1 from "assets/images/CountryStories/VietNam/engagement.png";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";

const Engagement = () => {
  return (
    <Grid
      container
      id="engagement"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Evolution of PEPFAR Vietnam Engagement with Local Communities
          </header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid container sx={{ height: "75vh" }}>
            <div className="image-frame">
              <Image
                fit={"contain"}
                tabIndex={0}
                src={image1}
                alt="This slide shows the evolution of PEPFAR engagement with local communities, and how they have evolved in the HIV response in Vietnam, from self-help groups, to community-based organizations, leading civil society organizations, and social enterprises."
              />
            </div>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Engagement;
