import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import logoImage from "assets/images/awareness-box-worldaidsday.png";
import { useSelector } from "react-redux";
import { selectapp } from "stateMgmt/appSlice";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import "./InTheSpotlight.scss";

const InTheSpotlight = (props) => {
  const appContext = useSelector(selectapp).context;
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const hide = appContext !== "home";
    setIsHidden(hide);
  });

  return (
    <>
      <Box className="in-spotlight-row" size={{ lg: 12, sm: 6 }}>
        <Grid
          container
          rowSpacing="4vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item size={{ lg: 12, sm: 6 }}>
            <Box
              spacing={0}
              alignItems="center"
              justifyContent="center"
              sx={{ maxHeight: "25vh" }}
            >
              <NavLink
                data-testid="dossierLink"
                to="https://www.state.gov/pepfar-latest-global-results-factsheet-dec-2024/"
                target="__blank"
                aria-hidden={isHidden}
                tabIndex={isHidden ? -1 : 1}
              >
                <div id="twentiethAnniversaryWidget" className="spot-icon-div">
                  <img
                    className="spot-img"
                    tabIndex={1}
                    src={logoImage}
                    id="videoHover"
                    data-testid="vidBtn_2"
                    alt="Click here to open the PEPFAR Latest Global Results Fact Sheet 2024"
                  ></img>
                </div>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InTheSpotlight;
