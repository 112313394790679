import React from "react";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Image from "mui-image";
import Typography from "@mui/material/Typography";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image1 from "assets/images/CountryStories/Dreams/map.png";

import "../CountryPages.scss";

const Empowered = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="empowered"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title dreams-title"
          sx={storyHeader}
        >
          <header tabIndex={0} style={{ color: theme.dreamsHilite1 }}>
            Determined, Resilient, Empowered, AIDS-free, Mentored, Safe
          </header>
        </Grid>
        <Grid size={12} sx={storyBody}>
          <Grid
            container
            sx={{
              mt: "1vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid size={{ sm: 12, md: 12, lg: 5, xl: 7, ultra: 5, curved: 4 }}>
              <List
                sx={{
                  pl: 5,
                  lineHeight: "1.2",
                  color: theme.color,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    PEPFAR announced the flagship{" "}
                    <font
                      style={{ fontWeight: 800, color: theme.dreamsHilite2 }}
                    >
                      DREAMS program
                    </font>{" "}
                    on World AIDS Day 2014.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1" tabIndex={0}>
                    Now implemented in{" "}
                    <font
                      style={{ fontWeight: 800, color: theme.dreamsHilite2 }}
                    >
                      15 countries
                    </font>
                    , DREAMS provides a{" "}
                    <font
                      style={{ fontWeight: 800, color: theme.dreamsHilite2 }}
                    >
                      comprehensive package
                    </font>{" "}
                    of core interventions to address key factors that make
                    adolescent girls and young women (AGYW) particularly
                    <font
                      style={{ fontWeight: 800, color: theme.dreamsHilite2 }}
                    >
                      {" "}
                      vulnerable to HIV
                    </font>
                    .
                  </Typography>

                  <List
                    sx={{
                      pl: 10,
                      pt: 3,
                      lineHeight: "1.0",
                      color: theme.color,
                      listStyleType: "none",
                      "& .MuiListItem-root": {
                        display: "list-item",
                      },
                    }}
                  >
                    <ListItem tabIndex={0}>
                      <Typography variant="body1">
                        These include lack of access to youth-friendly health
                        services, lack of access to youth friendly information,
                        and barriers such as gender-based violence, exclusion
                        from economic opportunities, and a lack of access to
                        secondary school.
                      </Typography>
                    </ListItem>
                  </List>
                </ListItem>
              </List>
            </Grid>
            <Grid size={{ sm: 12, md: 12, lg: 7, xl: 5, ultra: 7, curved: 8 }}>
              <div className="image-frame">
                <Image
                  tabIndex={0}
                  fit="contain"
                  duration={125}
                  src={image1}
                  style={{ height: "70vh" }}
                  alt="DREAMS is being implemented across 15 countries, representing very diverse settings."
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default Empowered;
