import React from "react";
import Grid2 from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import image from "assets/images/CountryStories/Malawi/theChallenge.png";

import "../CountryPages.scss";

const TheChallenge = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid2
      container
      id="theChallenge"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>The Challenge</header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2
            container
            sx={{
              mt: "1vh",
              flexDirection: {
                sm: "column-reverse",
                md: "column-reverse",
                lg: "row",
              },
            }}
          >
            <Grid2 size={{ sm: 12, md: 12, lg: 6, xl: 4, curved: 7 }}>
              <List
                sx={{
                  px: 10,
                  lineHeight: "1.75",
                  color: theme.color,
                  listStyleType: "none",
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    For many years, the recipients of care (RoC) at Mikolongwe
                    Health Centre faced a significant barrier to getting care.
                  </Typography>
                </ListItem>
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    RoC had long waiting times before accessing HIV services.
                    The challenges were compounded by the fact that recipients
                    did not know how or to whom they should voice their
                    concerns.
                  </Typography>
                </ListItem>
                <ListItem tabIndex={0}>
                  <Typography variant="body1">
                    In July 2023, RoC through a CLM routine focus group
                    discussion raised their concern.
                  </Typography>
                </ListItem>
              </List>
            </Grid2>
            <Grid2 size={{ sm: 12, md: 12, lg: 6, xl: 8, curved: 5 }}>
              <Grid2 size={12}>
                <div className="image-frame">
                  <Image
                    tabIndex={0}
                    sx={{
                      height: "65vh !important",
                    }}
                    src={image}
                    alt="Picture 3 depicts a CLM Community Monitor conducting a Focus Group Discussion with women living with HIV at Mikolongwe Health Centre."
                  />
                </div>
              </Grid2>
              <Grid2 size={12}>
                <Typography
                  variant="body2"
                  style={{ textAlign: "center", padding: "0 2vw 0 2vw" }}
                >
                  A CLM Community Monitor conducting a Focus Group Discussion
                  with women living with HIV at Mikolongwe Health Centre.
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default TheChallenge;
