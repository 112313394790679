import React from "react";
import Grid2 from "@mui/material/Grid2";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import Image from "mui-image";
import Typography from "@mui/material/Typography";
import { storyHeader, storyBody } from "components/Layout";
import image1 from "assets/images/CountryStories/CDI/presentationLeft.jpg";
import image2 from "assets/images/CountryStories/CDI/presentationRight.jpg";
import logos from "assets/images/CountryStories/CDI/logos.png";

import "../CountryPages.scss";

const Leading = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid2
      container
      id="leading"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            We Make Sure: Ivorian communities are leading with data
          </header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2
            container
            size={12}
            spacing={2}
            rowSpacing={0}
            style={{ height: "80vh" }}
          >
            <Grid2 container size={12} rowSpacing={0} sx={{ px: "10%", py: 0 }}>
              <Typography
                tabIndex={0}
                variant="h3"
                style={{ textAlign: "center", color: theme.color }}
              >
                <font style={{ color: theme.cdiHilite1 }}>
                  PEPFAR’s Community-Led Monitoring (CLM) investments{" "}
                </font>
                in Côte d’Ivoire engages{" "}
                <font style={{ color: theme.cdiHilite1 }}>
                  Community-Led Organizations
                </font>{" "}
                as transformative partners, equipped with their own data to help
                inform decisions for the future.
              </Typography>
            </Grid2>
            <Grid2
              container
              rowSpacing={0}
              size={12}
              style={{
                height: "52vh",
                // overflow: "hidden",
              }}
            >
              <Grid2 container size={12}>
                <Grid2 container size={6}>
                  <Grid2 item size={12}>
                    <div className="image-frame">
                      <Image
                        style={{ maxHeight: "45vh" }}
                        src={image1}
                        fit="contain"
                        alt="presentation image"
                      />
                    </div>
                  </Grid2>
                  <Grid2 item>
                    <Typography
                      tabIndex={0}
                      variant="h3"
                      style={{ textAlign: "center", color: theme.color }}
                    >
                      Advocacy Officers and CLOs select name for Côte
                      d’Ivoire&apos;s program in local language: YENINSOU =
                      &quot;Nous y veillons&quot; (French) = &quot;We make
                      sure&quot; (English)
                    </Typography>
                  </Grid2>
                </Grid2>
                <Grid2 container size={6}>
                  <Grid2 item size={12}>
                    <div className="image-frame">
                      <Image
                        style={{ maxHeight: "45vh" }}
                        src={image2}
                        fit="contain"
                        alt="presentation image"
                      />
                    </div>
                  </Grid2>
                  <Grid2 item>
                    <Typography
                      tabIndex={0}
                      variant="h3"
                      style={{ textAlign: "center", color: theme.color }}
                    >
                      Participants attending a regional advocacy taskforce
                      meeting in Bondoukou, including: ONG EVEIL, UNAIDS,
                      PEPFAR, and regional authorities.
                    </Typography>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2
              size={12}
              container
              style={{ maxHeight: "20vh" }}
              rowSpacing={0}
            >
              <div className="image-frame">
                <Image
                  tabIndex={0}
                  fit="contain"
                  src={logos}
                  alt="List of partner agencies: US PRESIDENTS EMERGENCY PLAN FOR AIDS RELIEF, JOINT UNITED NATIONS PROGRAMME ON HIV/AIDS, IVORIAN NETWORK OF ORGANIZATIONS OF PEOPLE LIVING WITH AIDS, NETWORK OF KEY POPULATION ORGANIZATIONS IN CôTE D’IVOIRE, NON-GOVERNMENTAL ORGANIZATION FOR THE DEVELOPMENT OF WOMENS ACTIVITIES, NON-GOVERNMENTAL ORGANIZATION EVEIL."
                />
              </div>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default Leading;
