import React from "react";

/* eslint-disable */
export default ({ webp, src, testId, id, ...rest }) => (
  <picture>
    <source
      data-testid={`${testId}_1`}
      srcSet={webp}
      id={`${id}1`}
      {...rest}
      type="image/webp"
      width="100%"
      height="100%"
      object-fit="cover"
    />
    <source
      data-testid={`${testId}_2`}
      srcSet={src}
      id={`${id}2`}
      {...rest}
      width="100%"
      height="100%"
      object-fit="cover"
    />
    <img
      data-testid={`${testId}_3`}
      src={src}
      id={`${id}3`}
      {...rest}
      width="100%"
      height="100%"
      object-fit="cover"
    />
  </picture>
);
/* eslint-enable */
