import "babel-polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import CacheBuster from "react-cache-buster";
import version from "../package.json";
import * as serviceWorker from "./registerServiceWorker";
import "./bootstrap-css-only/css/bootstrap.min.css";
import "./bootstrap-overrides.scss";
import App from "./App";
import store from "./stateMgmt/store";
import { Provider } from "react-redux";
import logger from "./services/LoggingService";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import { ThemeProvider } from "@mui/material/styles";
import { getSpotlightTheme } from "services/SpotlightTheme";
import { CssBaseline } from "@mui/material";

import "./index.scss";

const isProduction = process.env.NODE_ENV === "production";
const container = document.getElementById("root");
const root = createRoot(container);
logger.error("INDEX: ", process.env.REACT_APP_ANA);
ReactGA.initialize(process.env.REACT_APP_ANA, { debug: true });

ReactGA.send({
  hitType: "pageview",
  page: "./index.js",
  title: "Visit Spotlight Site",
});

const tagManagerArgs = {
  gtmId: "GTM-MRHVX6B",
};

TagManager.initialize(tagManagerArgs);

root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction} // If false, the library is disabled.
    isVerboseMode={false} // If true, the library writes verbose logs to console.
  >
    <Provider store={store}>
      <ThemeProvider theme={getSpotlightTheme()}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </CacheBuster>,
);

serviceWorker.unregister();
