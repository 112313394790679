import React from "react";
import BasicBarChart from "factories/BasicBar";
import PropTypes from "prop-types";
import "./PrimaryDataFigure.scss";

function PrimaryDataChart(props) {
  return (
    <>
      <BasicBarChart
        labels={props.chartData.labels}
        data={props.chartData.data}
        rotateLabels={25}
      />
    </>
  );
}

PrimaryDataChart.propTypes = {
  chartData: PropTypes.object,
};

export default PrimaryDataChart;
