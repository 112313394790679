import React from "react";
import Grid from "@mui/material/Grid2";
import { storyHeader } from "components/Layout";
import Image from "mui-image";
import image1 from "assets/images/CountryStories/VietNam/clinics.png";
import "../CountryPages.scss";

const Clinics = () => {
  return (
    <Grid
      container
      id="clinics"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Key Population-Led Private Clinics Play Key Roles in the PrEP
            Program
          </header>
        </Grid>
        <Grid container sx={{ height: "70vh" }}>
          <div className="image-frame">
            <Image
              fit={"contain"}
              // tabIndex={0}
              src={image1}
              // alt="Key-population (KP) led private clinics have played a key role in PrEP service delivery in Vietnam. This graph illustrates the comprehensive approaches that KP-led clinics have put in place to improve accessibility and quality of health services provided to clients. These includes, for example, from flexible working hours, wide range of health services provided, routine monitoring of service quality and various channels of collecting clients’ feedback to implementing creative demand generation campaigns."
            />
          </div>
        </Grid>
      </article>
      <Grid
        container
        size={12}
        sx={{ position: "absolute", top: 40, left: 0 }}
        style={{ width: "100%", height: "100%" }}
      >
        <Grid
          size={6}
          tabIndex={0}
          role="presentation"
          aria-label="Accessibility. Make services accessible through mobile events and flexible
              working hours. Referring clients to PrEP services."
        ></Grid>
        <Grid
          size={6}
          tabIndex={0}
          role="presentation"
          aria-label="Comprehensive services. Provide person-centered services through One-Stop Shop models. Support multiple entry points (e.g. STIs, mental health) to
                refer to PrEP and HIV prevention services."
        ></Grid>
        <Grid
          size={6}
          tabIndex={0}
          role="presentation"
          aria-label="Innovative demand creation. Creative demand generation strategies. Campaigns to reach young key population (university/college
                students) and mobile workers."
        ></Grid>
        <Grid
          size={6}
          tabIndex={0}
          role="presentation"
          aria-label="Quality and KP-friendly services. Routine monitoring of service quality using national standard indicators. Continuous quality improvement with diversified client feedback channels."
        ></Grid>
      </Grid>
    </Grid>
  );
};

export default Clinics;
