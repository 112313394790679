import React, { useEffect, useState } from "react";
import mstrDashboardsConfig from "assets/configs/mstr-dashboards-config.json";
import MSTRPageContainer from "components/MainContentArea/MSTRPageContainer/MSTRPageContainer";
import * as common from "common";
import loadingSvgImage from "assets/images/loading.svg";
import logger from "services/LoggingService";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";

const DashboardPage = (props) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/DashboardPage/DashboardPage.js",
    title: "Dashboard",
  });

  const [iFrameLoadedSuccessfully, setIFrameLoadedSuccessfully] =
    useState(false);

  const [keyID, setKeyID] = useState(0);

  const dashboardName = props.dashboardName;

  const loadingSpinnerStyle = {
    textAlign: "center",
  };

  const iFrameNotLoadingInfoMessage = (
    <div className="iframe-not-loading__div" style={loadingSpinnerStyle}>
      <img src={loadingSvgImage} alt="loading" />
      <p>Loading...</p>
    </div>
  );
  let timerID = null;
  let mstrIFrameDivLeftMargin = null;

  let urlToUse = null;
  if (common.useMicrostrategyDev()) {
    urlToUse = "devURL";
  } else {
    urlToUse = "prodURL";
  }

  window.clearInterval(timerID);

  useEffect(() => {
    /* Note: Set this refreshTimeInterval to a value < the MSTR 'web user idle session', currently set to 176 minutes */
    const refreshTimeInterval = 9900000; // 2 Hours 45 Minutes
    // let refreshTimeInterval = 240000;   //4 minutes - Can be used for TESTING

    if (props.isSidebarCollapsed) {
      mstrIFrameDivLeftMargin = { left: "0px" };
    }

    timerID = window.setInterval(refreshIFrameContents, refreshTimeInterval);
  });

  function iFrameLoaderListener() {
    setIFrameLoadedSuccessfully(true);
  }

  function refreshIFrameContents() {
    /* Note: In order to trigger a re-render of the iFrame using this.forceUpdate(), something within the iFrame needs to change
            In this case, I am forcing an update by changing the key of the iFrame */
    setKeyID((keyID) => keyID + 1);
    logger.debug(`New keyID: ${keyID}`);
    // forceUpdate();
  }

  const iFrameContents = (
    <iframe
      id="dashFrame"
      data-testid="dashboardsIFrame"
      width="100%"
      frameBorder="0"
      title={dashboardName + " MSTR iFrame"}
      key={keyID}
      src={
        mstrDashboardsConfig.mstrDashboardLinks.find(function (element) {
          return element.name === dashboardName;
        })[urlToUse]
      }
      onLoad={iFrameLoaderListener}
    />
  );

  const pageContents = (
    <div id="mstr-iframe__div" style={mstrIFrameDivLeftMargin}>
      {iFrameLoadedSuccessfully ? <></> : iFrameNotLoadingInfoMessage}
      {iFrameContents}
    </div>
  );

  return (
    <MSTRPageContainer
      contents={pageContents}
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

DashboardPage.propTypes = {
  dashboardName: PropTypes.string.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default DashboardPage;
