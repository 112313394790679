import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Grid2";
import * as echarts from "echarts";
import { chartColors } from "components/CountryStories/charts";

import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import { getChartFontSize, getChartTitleSize } from "common";
import "../CountryPages.scss";

const ContributionsTwo = () => {
  const theme = useSelector(selectapp).theme;
  let chart1;
  const stackedBar = {
    grid: {
      show: false,
    },
    aria: {
      show: false,
    },
    title: {
      text: "The community has increasingly contributed\nto the overall new HIV+ case finding",
      left: "center",
      top: "top",
      textStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontWeight: 800,
      },
    },
    legend: {
      orient: "vertical",
      bottom: "50%",
      left: "85%",
      textStyle: { color: theme.color },
    },
    xAxis: {
      data: ["FY22", "FY23", "FY24(Q1-3)"],
      axisLabel: {
        textStyle: {
          fontSize: getChartFontSize(),
          fontWeight: 800,
          color: theme.color,
        },
      },
    },
    yAxis: { type: "value", min: 0, max: 100, axisLabel: { show: false } },
    series: [
      {
        name: "Community",
        data: [71, 73, 74],
        type: "bar",
        barWidth: "30%",
        itemStyle: {
          color: chartColors[0],
          borderWidth: 1,
          borderRadius: 0,
          borderType: "solid",
          borderColor: "#333333",
          shadowColor: "#5470c6",
        },
        color: chartColors[0],
        stack: "x",
        label: {
          show: true,
          textStyle: { fontSize: getChartFontSize(), fontWeight: 800 },
          formatter: function (params) {
            return `${params.value}%`;
          },
        },
      },
      {
        name: "Others",
        data: [100, 100, 100],
        color: "#cccccc",
        type: "bar",
        stack: "x",
        barWidth: "30%",
        itemStyle: {
          color: "#cccccc",
          borderWidth: 1,
          borderRadius: 0,
          borderType: "solid",
          borderColor: "#333333",
          shadowColor: "#5470c6",
        },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
    center: ["100%", "50%"],
  };

  useEffect(() => {
    chart1 = echarts.init(document.getElementById("theChart"));
    chart1.setOption(stackedBar);
  }, []);

  return (
    <Grid2
      container
      id="contributions2"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Community Contributions to 95-95-95: Tay Ninh Province
          </header>
        </Grid2>
        <Grid2 container size={12} sx={storyBody}>
          <Grid2 id="row1" size={12} sx={{ mx: "5vw" }}>
            <Typography variant="body1" tabIndex={0} sx={{ fontWeight: 800 }}>
              Achieving the goal
            </Typography>
            <Typography variant="body1" tabIndex={0}>
              Community-based organizations/social enterprises have contributed
              significantly to case finding in Tay Ninh, a southeastern province
              of Vietnam neighboring Ho Chi Minh City - the most populous city
              in the country. The HIV epidemic in this province is concentrated
              in key populations.
            </Typography>
          </Grid2>
          <Grid2
            id="row2"
            container
            size={12}
            sx={{ mx: "5vw", height: "50vh" }}
          >
            <Grid2 size={8}>
              <div
                role="presentation"
                tabIndex={0}
                aria-label="interactive chart showing from FY22 to FY23, community organizations contributed 71% and 73% new HIV+ case finding, respectively. From Q1 to Q3 of FY24, contribution has increased to 74%."
                id="theChart"
                className="image-frame"
              />
            </Grid2>
            <Grid2 size={4}>
              <Typography variant="body1" tabIndex={0} sx={{ fontWeight: 800 }}>
                Elevating the community’s role in the national program
              </Typography>
              <Typography variant="body1" tabIndex={0}>
                The community has made a significant contribution to HIV+ case
                finding activities and in achieving the provincial first 95
                goal. Along with other efforts, this has highlighted the
                community as a key player in various activities of Vietnam’s
                National Strategy to End AIDS by 2030.
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default ContributionsTwo;
