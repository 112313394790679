import React from "react";
import CountryStoryMain from "components/CountryStories/CountryStoryMain";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { selectapp } from "stateMgmt/appSlice";
import InTheSpotlight from "./InTheSpotlight/InTheSpotlight";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import "components/CountryStories/CountryStoryMain.scss";

const carouselRow = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <>
      <div className="carousel-div">
        <Grid
          container
          spacing={2}
          alignItems="start"
          justifyContent="space-between"
        >
          <Grid item={"true"} size={{ lg: 9, sm: 12 }}>
            <Box>
              <CountryStoryMain />
            </Box>
          </Grid>
          <Grid item={"true"} size={{ lg: 3, sm: 12 }}>
            <Box>
              <InTheSpotlight />
            </Box>
            <Box>
              <Typography
                variant="h3"
                style={{ fontSize: "1.0vw", color: theme.color }}
                role="heading"
              >
                Click the image above for PEPFARs 2024 Global Results Fact Sheet
              </Typography>
              {/* <Link
                variant="h5"
                underline="hover"
                aria-label="Click to open PEPFAR Latest Global Results Fact Sheet 2024 in new tab."
                style={{ color: theme.linkColor }}
                href={
                  "https://www.state.gov/pepfar-latest-global-results-factsheet-dec-2024/"
                }
                target="__blank"
              >
                Latest PEPFAR Global Results Fact Sheet (2024)
              </Link> */}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default carouselRow;
