import { createSlice } from "@reduxjs/toolkit";
import fontStyles from "../Site-Globals.scss";
import dreamsBannerDk from "assets/images/Banners/DreamsBannerDk.png";
import cdiBannerDk from "assets/images/Banners/CdiBannerDk.png";
import malawiBannerDk from "assets/images/Banners/MalawiBannerDk.png";
import vietnamBannerDk from "assets/images/Banners/VietnamBannerDk.png";
import dreamsBannerLt from "assets/images/Banners/DreamsBannerLt.png";
import cdiBannerLt from "assets/images/Banners/CdiBannerLt.png";
import malawiBannerLt from "assets/images/Banners/MalawiBannerLt.png";
import vietnamBannerLt from "assets/images/Banners/VietnamBannerLt.png";

export const dark = {
  backgroundColor: "black",
  asideBackgroundColor: "black",
  secondaryColor: "#212121",
  bannerColor: "#1a2439",
  color: "#ffffff",
  linkColor: fontStyles.siteLtBlue,
  buttonColor: "#58a6ff",
  tableHeaderColor: "#000000",
  tableHeaderFontColor: "#ffffff",
  tableFirstRowColor: "#2e2e2e",
  tableEvenColor: "#5c5c5c",
  tableOddColor: "#454545",
  warningColor: "#FF0000",
  liteFontColor: "#ffffff",
  headerBorder: "3px solid #ffffff",
  bgGradient:
    "rgb(107,133,235) linear-gradient(0deg, rgba(107,133,235,0.8) 25%, rgba(5,17,140,1) 100%)",
  dreamsHilite1: "#ffffff",
  dreamsHilite2: "#ffffff",
  dreamsHilite3: "#ffffff",
  dreamsHilite4: "#ffffff",
  dreamsHilite5: "#ffffff",
  vietnamHilite1: "#ffffff",
  vietnamHilite2: "#ffffff",
  cdiHilite1: "#ffffff",
  cdiHilite2: "#ffffff",
  cdiHilite3: "#ffffff",
  dreamsBanner: dreamsBannerLt,
  cdiBanner: cdiBannerLt,
  malawiBanner: malawiBannerLt,
  vietnamBanner: vietnamBannerLt,
};

export const light = {
  backgroundColor: "white",
  asideBackgroundColor: "#f0f0f0",
  secondaryColor: "#EEEEEE",
  bannerColor: "#1a2439",
  color: "#000000",
  linkColor: "#0000FF",
  buttonColor: "#0000FF",
  tableHeaderColor: "#18477D",
  tableHeaderFontColor: "#ffffff",
  tableFirstRowColor: "#919191",
  tableEvenColor: "#e6e6e6",
  tableOddColor: "#c9c9c9",
  warningColor: "#be0000",
  liteFontColor: "#596067",
  headerBorder: "6px solid #be0000",
  bgGradient: "white",
  dreamsHilite1: "#d97108",
  dreamsHilite2: "#2B236E",
  dreamsHilite3: "#2C7C83",
  dreamsHilite4: "#8D1F23",
  dreamsHilite5: "#CA6333",
  vietnamHilite1: "#1B2755",
  vietnamHilite2: "#C00630",
  cdiHilite1: "#006600",
  cdiHilite2: "#E36C08",
  cdiHilite3: "#1292E8",
  dreamsBanner: dreamsBannerDk,
  cdiBanner: cdiBannerDk,
  malawiBanner: malawiBannerDk,
  vietnamBanner: vietnamBannerDk,
};

const isDarkMode = localStorage.getItem("dark") === "true";

const initialState = {
  context: "home",
  currentStoryNumber: 0,
  theme: isDarkMode ? { ...dark } : { ...light },
};

export const appSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    updateappState: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    toggleTheme: (state) => {
      const isDark = localStorage.getItem("dark") === "true";
      const newMode = isDark ? { ...light } : { ...dark };
      localStorage.setItem("dark", JSON.stringify(!isDark));
      const newState = {
        ...state,
        theme: { ...newMode },
      };
      return newState;
    },
  },
});

export const selectapp = (state) => state.appState;
export const selectTheme = (state) => state.appState.theme;
export const selectContext = (state) => state.appState.context;
export const { updateappState, toggleTheme, updateSlide } = appSlice.actions;
export default appSlice.reducer;
