import React from "react";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import image1 from "assets/images/CountryStories/VietNam/HCMCPlan.jpg";

import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";

import "../CountryPages.scss";

const RealTimeOne = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid2
      container
      id="realTimeOne"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title nam-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            Optimizing Real-Time Data for Effective Community Outreach &
            Referrals
          </header>
        </Grid2>
        <Grid2 container size={12} sx={storyBody}>
          <Grid2 container size={12} columnGap={2} sx={{ mx: "2vw" }}>
            <Grid2 size={12} sx={{ m: 0, p: 0 }}>
              <Typography
                variant="h2"
                tabIndex={0}
                style={{ height: "4vh" }}
                sx={{ color: theme.color, mt: 2, mb: 0, p: 0 }}
              >
                Before system interoperability intervention:
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2
            container
            size={6}
            style={{ height: "54vh" }}
            sx={{ m: 0, p: 0 }}
          >
            <Grid2 size={12} sx={{ ml: 3, mt: 1 }} item>
              <Typography
                tabIndex={0}
                variant="body1"
                sx={{ color: theme.color }}
              >
                <strong>Paper-Based Referrals</strong> between community and
                facilities
              </Typography>
            </Grid2>
            <Grid2 size={12} sx={{ ml: 3, mt: 1 }} item>
              <Typography
                tabIndex={0}
                variant="body1"
                sx={{ color: theme.color }}
              >
                <strong>Double Data Entry</strong> in community apps and
                facilities software
              </Typography>
            </Grid2>
            <Grid2 size={12} sx={{ ml: 3, mt: 1 }} item>
              <Typography
                tabIndex={0}
                variant="body1"
                sx={{ color: theme.color }}
              >
                <strong>Difficult to Track Referral Success</strong> as no
                streamlined system existed to easily verify
              </Typography>
            </Grid2>
            <Grid2 size={12} sx={{ ml: 3, mt: 1 }} item>
              <Typography
                tabIndex={0}
                variant="body1"
                sx={{ color: theme.color }}
              >
                <strong>Data Duplication:</strong> Potential for data
                duplication in reports
              </Typography>
            </Grid2>
            <Grid2 size={12} sx={{ ml: 3, mt: 1 }} item>
              <Typography
                tabIndex={0}
                variant="body1"
                sx={{ color: theme.color }}
              >
                <strong>Non-Standardized Community Data</strong> and Forms
              </Typography>
            </Grid2>
            <Grid2 size={12} sx={{ ml: 3, mt: 1 }} item>
              <Typography
                tabIndex={0}
                variant="body1"
                sx={{ color: theme.color }}
              >
                <strong>Lack of Information Sharing:</strong> Outreach workers
                were not informed of clients&apos; testing results or ART/PrEP
                treatment status in health facilities, delaying support
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2
            container
            size={6}
            style={{ height: "60vh" }}
            sx={{ m: 0, px: 3 }}
          >
            <div className="image-frame-top">
              <Image
                style={{ borderRadius: 16 }}
                fit="contain"
                src={image1}
                alt="In Sept 2023, Ho Chi Minh City approved a plan to upgrade community health information systems and referrals, aiming to address existing issues."
              />
            </div>
          </Grid2>
          <Grid2 size={12}>
            <Grid2 size={12} sx={{ pt: "2vh" }}>
              <Typography
                variant="body1"
                tabIndex={0}
                sx={{
                  color: theme.vietnamHilite2,
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Challenge Identified:
                <br />
                Only 48% of client records matched between community and
                facilities databases
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default RealTimeOne;
