import React, { memo, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  A11y,
  EffectFade,
  Keyboard,
  Autoplay,
} from "swiper";

import { useDispatch, useSelector } from "react-redux";
import CountryModalContainer from "./CountryModalContainer";
import "./CountryStoryMain.scss";
import { updateappState, selectapp, selectTheme } from "stateMgmt/appSlice";
import { updateSlide } from "stateMgmt/swiperSlice";
import ally from "ally.js";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import { v4 as uuid } from "uuid";
import * as common from "common";
import logger from "services/LoggingService";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const CountryStoryMain = memo(function CountryStoryMain(props) {
  const dispatch = useDispatch();
  const context = useSelector(selectapp).context;
  const theme = useSelector(selectTheme);
  const s3path = common.getS3Url();
  const [swiperInstance, setSwiperInstance] = useState(null);

  const setNextSlide = () => {
    if (swiperInstance !== null && context === "home") {
      dispatch(updateSlide({ currentStoryNumber: swiperInstance.realIndex }));
    }
  };

  const toggleModalOpen = (open) => {
    logger.debug(`toggleModalOpen: ${open}`);
    const newContext = open ? "countries" : "home";
    dispatch(
      updateappState({
        context: newContext,
      }),
    );

    if (open) {
      ally.maintain.tabFocus({
        context: "#story-modal",
      });
    }
  };

  useEffect(() => {
    if (swiperInstance && context === "home") {
      dispatch(updateSlide({ currentStoryNumber: swiperInstance.realIndex }));
    }
  }, [context]);

  return (
    <>
      <div>
        <CountryModalContainer />
      </div>

      <div className="banner-row">
        <Grid
          container
          spacing={2}
          data-testid="story-main-test"
          role="button"
          id="story-main-id"
        >
          <Grid item size={12}>
            <Box>
              <Swiper
                style={{
                  "--swiper-navigation-size": "2vw",
                  "--swiper-navigation-sides-offset": "0.1vw",
                }}
                modules={[
                  Navigation,
                  Pagination,
                  A11y,
                  EffectFade,
                  Keyboard,
                  Autoplay,
                ]}
                effect="fade"
                centeredSlides={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                loop={true}
                fadeEffect={{
                  crossFade: true,
                }}
                speed={1000}
                slidesPerView={1}
                keyboard={{
                  enabled: true,
                }}
                navigation
                pagination={{ clickable: true }}
                grabCursor={false}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                onSlideChange={() => {
                  setNextSlide();
                }}
              >
                <SwiperSlide>
                  <Card
                    key={uuid()}
                    variant="outlined"
                    sx={{
                      maxWidth: "88%",
                      margin: "auto",
                      backgroundColor: "#d4d1cf",
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        sx={{ p: 1 }}
                        image={theme.dreamsBanner}
                        alt="Dreams's carousel image. Click to open Dreams slide deck."
                        onClick={() => toggleModalOpen(true, 0)}
                      />
                    </CardActionArea>
                    <CardContent sx={{ background: "#f0f0f0" }}>
                      <Typography variant="h3" sx={{ p: 1 }} component="div">
                        DREAMS - Click image above to view story
                      </Typography>
                    </CardContent>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    key={uuid()}
                    variant="outlined"
                    sx={{
                      maxWidth: "88%",
                      margin: "auto",
                      backgroundColor: "#d4d1cf",
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        sx={{ p: 1 }}
                        image={theme.cdiBanner}
                        alt="Cote Diovoire's carousel image. Click to open Cote Diovoire's slide deck."
                        onClick={() => toggleModalOpen(true, 1)}
                      />
                    </CardActionArea>
                    <CardContent sx={{ background: "#f0f0f0" }}>
                      <Typography variant="h3" sx={{ p: 1 }} component="div">
                        C&#244;te d&#39;Ivoire - Click image above to view story
                      </Typography>
                    </CardContent>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    key={uuid()}
                    variant="outlined"
                    sx={{
                      maxWidth: "88%",
                      margin: "auto",
                      backgroundColor: "#d4d1cf",
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        sx={{ p: 1 }}
                        image={theme.malawiBanner}
                        alt="Malawi's carousel image. Click to open Malawi's slide deck."
                        onClick={() => toggleModalOpen(true, 2)}
                      />
                    </CardActionArea>
                    <CardContent sx={{ background: "#f0f0f0" }}>
                      <Typography variant="h3" sx={{ p: 1 }} component="div">
                        Malawi - Click image above to view story
                      </Typography>
                    </CardContent>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card
                    key={uuid()}
                    variant="outlined"
                    sx={{
                      maxWidth: "88%",
                      margin: "auto",
                      backgroundColor: "#d4d1cf",
                    }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        sx={{ p: 1 }}
                        image={theme.vietnamBanner}
                        alt="Vietnam's carousel image. Click to open Vietnam's slide deck."
                        onClick={() => toggleModalOpen(true, 3)}
                      />
                    </CardActionArea>
                    <CardContent sx={{ background: "#f0f0f0" }}>
                      <Typography variant="h3" sx={{ p: 1 }} component="div">
                        Vietnam - Click image above to view story
                      </Typography>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </Box>
          </Grid>
          <Grid item size={12}>
            <Box sx={{ fontSize: "1.0rem" }}>
              <Link
                variant="body2"
                underline="hover"
                style={{ color: theme.linkColor, fontWeight: 600 }}
                href={`${s3path}accessibility_downloads/2024_World_AIDS_Day_Data_Stories.pptx`}
                title="Link To 508 compliant deck."
                aria-label="Link To 508 compliant deck."
              >
                Click here to download an accessible PowerPoint version of the
                data stories
              </Link>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
});

export default CountryStoryMain;
