import React, { useEffect } from "react";
import Grid2 from "@mui/material/Grid2";
import * as echarts from "echarts";
import { getChartFontSize, getChartTitleSize, thousandsSep } from "common";
import { storyHeader, storyBody } from "components/Layout";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { chartColors } from "components/CountryStories/charts";
import "../CountryPages.scss";

let theChart;

const Contribution = () => {
  const theme = useSelector(selectapp).theme;
  const chartOptions = {
    title: {
      text: "PrEP_NEW in AGYW 15-24 in DREAMS Districts vs Non DREAMS Districts.",
      subtext: "",
      left: "center",
      textStyle: {
        fontSize: getChartTitleSize(),
        color: theme.color,
      },
      subtextStyle: {
        color: theme.color,
      },
    },
    legend: {
      orient: "horizontal",
      bottom: "bottom",
      left: "center",
      textStyle: { color: theme.color },
    },
    aria: {
      show: true,
      label: {
        description: "",
      },
    },
    color: chartColors,
    grid: { show: true, containLabel: true },
    xAxis: {
      name: "Year",
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        // padding: 10,
        color: "black",
        fontSize: getChartFontSize(),
        fontWeight: 600,
        rotate: 0,
      },
      data: ["2021", "2022", "2023", "2024"],
      nameTextStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontWeight: "bolder",
        align: "center",
        // padding: [50, 0, 0, 0],
      },
      nameLocation: "middle",
    },
    yAxis: {
      name: "New PrEP Initiations",
      type: "value",
      nameTextStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontWeight: "bolder",
        padding: [0, 0, 60, 0],
      },
      nameLocation: "middle",
    },
    series: [
      {
        name: "DREAMS",
        data: [274573, 347290, 473760, 474611],
        label: {
          show: true,
          position: "top",
          fontWeight: "bold",
          fontSize: getChartFontSize(),
          formatter: function (params) {
            return thousandsSep(params.value);
          },
        },
        type: "bar",
        barWidth: "20%",
        itemStyle: {
          //   color: "#07607F",
          borderWidth: 2,
          borderRadius: 0,
          borderType: "solid",
          borderColor: "#000000",
          shadowColor: "#5470c6",
        },
      },
      {
        name: "Non-DREAMS",
        data: [90040, 153875, 215249, 350201],
        label: {
          show: true,
          position: "top",
          fontWeight: "bold",
          fontSize: getChartFontSize(),
          formatter: function (params) {
            return thousandsSep(params.value);
          },
        },
        type: "bar",
        barWidth: "20%",
        itemStyle: {
          //   color: "#07607F",
          borderWidth: 2,
          borderRadius: 0,
          borderType: "solid",
          borderColor: "#000000",
          shadowColor: "#5470c6",
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      extraCssText: "box-shadow: 4px 6px 0px rgba(10, 10, 10, 0.3)",
    },
    center: ["100%", "50%"],
  };

  function initChart() {
    theChart = echarts.init(document.getElementById("theChart"));
    theChart.setOption(chartOptions);
  }

  useEffect(() => {
    initChart();
  }, []);

  return (
    <Grid2
      container
      id="contribution"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>
            DREAMS Contribution to Pre-exposure Prophylaxis (PrEP) Initiations
          </header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2 container sx={{ height: "70vh" }}>
            <div
              tabIndex={0}
              role="presentation"
              aria-label="Interactive chart: DREAMS programming contribution to pre-exposure prophylaxis (PrEP) imitations among Adolescent Girls and Young Women (AGYW) ages 15-24 years."
              id="theChart"
              className="image-frame"
            />
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default Contribution;
