import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import Image from "mui-image";
import { storyHeader, storyBody } from "components/Layout";
import icon1 from "assets/images/CountryStories/CDI/Icon1.png";
import icon2 from "assets/images/CountryStories/CDI/Icon2.png";
import icon3 from "assets/images/CountryStories/CDI/Icon3.png";
import icon4 from "assets/images/CountryStories/CDI/Icon4.png";
import scale from "assets/images/CountryStories/CDI/scale.png";
import Grid2 from "@mui/material/Grid2";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import mapData from "assets/data/maps/Cote/CotedIvoire_5_HealthDistricts_2023_Jul.json";

import "../CountryPages.scss";
import logger from "../../../../services/LoggingService";

const Effort = () => {
  let theMap;
  const [showScale, setShowScale] = useState(true);
  const theme = useSelector(selectapp).theme;
  const mapOptions = {
    title: {
      show: true,
      text: "Interactive map of the 21 Health SDistricts covered by CLM as of FY 2024",
      subtext: "Use mouse wheel to zoom. Hover mouse over region for tooltip.",
      left: "center",
      // top: "bottom",
      bottom: 2,
      textStyle: {
        fontSize: "12",
        color: theme.color,
        fontWeight: 800,
        overflow: "break",
      },
      subtextStyle: {
        fontSize: 12,
        fontWeight: 800,
        color: theme.color,
      },
    },
    visualMap: {
      type: "piecewise",
      show: false,
      min: 0,
      max: 1,
      range: [0, 1],
      inRange: {
        color: ["#E26C08"],
      },
    },
    graphic: {
      type: "image", // https://www.youtube.com/watch?v=ygFDhmbPU-Y
      id: "scale",
      left: "center", // Position at the center horizontally.
      bottom: "top", // Position beyond the bottom boundary 10%.
      image: { scale },
      width: 200,
      height: 50,
    },
    grid: { show: false, top: 0, right: 5, bottom: "auto", left: 5 },
    tooltip: {
      trigger: "item",
      position: "top",
      formatter: function (params) {
        return params.name;
      },
    },
    toolbox: {
      show: true,
      orient: "vertical",
      x: "right",
      y: "center",
      backgroundColor: "#ddd",
      color: "black",
      feature: {
        restore: {},
        saveAsImage: {},
      },
    },
    series: [
      {
        type: "map",
        map: "CDI",
        roam: true,
        aspectScale: 0.95,
        scaleLimit: { min: 1, max: 15 },
        animation: true,
        animationDurationUpdate: 1000,
        universalTransition: true,
        labelLayout: { hideoverlap: true },
        label: {
          show: false,
          formatter: function (params) {
            return params.value === 1 ? params.name : "";
          },
        },
        data: [
          {
            name: "Health Districts",
            value: 0,
          },
          { name: "Abengourou", value: 1 },
          { name: "Abobo-Est", value: 1 },
          { name: "Abobo-Ouest", value: 1 },
          { name: "Adjame-Plateau-Attecoube", value: 1 },
          { name: "Agboville", value: 1 },
          { name: "Bondoukou", value: 1 },
          { name: "Bouake-Nord-Ouest", value: 1 },
          { name: "Bouake-Sud", value: 1 },
          { name: "Cocody-Bingerville", value: 1 },
          { name: "Daloa", value: 1 },
          { name: "Gagnoa 1", value: 1 },
          { name: "Korhogo 1", value: 1 },
          { name: "Kouamassi", value: 1 },
          { name: "Man", value: 1 },
          { name: "Port-Bouet-Vridi", value: 1 },
          { name: "San-Pedro", value: 1 },
          { name: "Soubre", value: 1 },
          { name: "Treichville-Marcory", value: 1 },
          { name: "Yamoussoukro", value: 1 },
          { name: "Yopougon-Est", value: 1 },
          { name: "Yopougon-Ouest-Songon", value: 1 },
        ],
      },
    ],
  };

  const setScale = (zoomLevel) => {
    if (showScale === true && zoomLevel > 1) {
      setShowScale(false);
      logger.debug("hide", showScale);
    } else if (showScale === false && zoomLevel === 1) {
      setShowScale(true);
      logger.debug("show", showScale);
    }
  };

  useEffect(() => {
    echarts.registerMap("CDI", mapData, {});

    theMap = echarts.init(document.getElementById("themap"));
    theMap.setOption(mapOptions, true);

    theMap.on("georoam", function (params) {
      logger.debug("georoam", params);
      setScale(params.totalZoom);
    });
  }, []);

  return (
    <Grid2
      container
      id="effort"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>CLM : PEPFAR-Supported Effort</header>
        </Grid2>
        <Grid2 container size={12} sx={storyBody}>
          <Grid2 container size={12} sx={{ height: "75vh", mt: 2, mx: 2 }}>
            <Grid2
              container
              size={5}
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
            >
              <div
                tabIndex={0}
                role="presentation"
                aria-label="A map of Cote d'Ivoire with orange color as areas where CLM is implemented."
                id="themap"
                className="image-frame"
              ></div>
            </Grid2>
            <Grid2 container size={7}>
              <Grid2 item size={12}>
                <Box className="icon-style">
                  <Box>
                    <Image
                      src={icon1}
                      height="13vh"
                      fit="contain"
                      style={{ margin: "auto" }}
                      alt="decorative photo."
                    />
                  </Box>

                  <Typography
                    tabIndex={0}
                    variant="body2"
                    style={{ color: "#000000" }}
                  >
                    <div className="icon-text">
                      <strong>GOAL</strong>: Strengthen community-led monitoring
                      efforts focused on{" "}
                      <strong>national policy implementation</strong> and{" "}
                      <strong>HIV service quality improvement.</strong>
                    </div>
                  </Typography>
                </Box>
                <Box></Box>
              </Grid2>
              <Grid2 item size={12}>
                <Box className="icon-style">
                  <Image
                    src={icon2}
                    height="13vh"
                    fit="contain"
                    style={{ margin: "auto" }}
                    alt="decorative photo."
                  />
                  <Typography
                    tabIndex={0}
                    variant="body2"
                    style={{ color: "#000000" }}
                  >
                    <div className="icon-text">
                      <strong>4 CLOs</strong>: RIP+, ROPC-CI, ONG EVEIL, ONG
                      ODAFEM{" "}
                      <strong>
                        <br />
                        54 Data Collectors and Supervisors
                      </strong>{" "}
                      <br />
                      (2 additional CLOs joining, including RIJES, a
                      youth-network)
                    </div>
                  </Typography>
                </Box>
                <Box></Box>
              </Grid2>
              <Grid2 item size={12}>
                <Box className="icon-style">
                  <Image
                    src={icon3}
                    height="13vh"
                    fit="contain"
                    style={{ margin: "auto" }}
                    alt="decorative photo."
                  />
                  <Typography
                    tabIndex={0}
                    variant="body2"
                    style={{ color: "#000000" }}
                  >
                    <div className="icon-text">
                      21 Health Districts covering largest population centers
                      <br />
                      <font style={{ fontSize: "0.6rem", lineHeight: 0.8 }}>
                        Abengourou, Abobo-Est, Abobo-Ouest,
                        Adjame-Plateau-Attecoube, Agboville, Bondoukou,
                        Bouake-Nord-Ouest, Bouake-Sud, Cocody-Bingerville,
                        Daloa, Gagnoa 1, Korhogo 1, Kouamassi, Man,
                        Port-Bouet-Vridi, San Pedro, Soubre,
                        Treichville-Marcory, Yamoussoukro, Yopougon-Est, and
                        Yopougon-Ouest-Songon.
                      </font>
                    </div>
                  </Typography>
                </Box>
                <Box></Box>
              </Grid2>
              <Grid2 item size={12}>
                <Box className="icon-style">
                  <Image
                    src={icon4}
                    height="13vh"
                    fit="contain"
                    style={{ margin: "auto" }}
                    alt="decorative photo."
                  />
                  <Typography
                    tabIndex={0}
                    variant="body2"
                    style={{ color: "#000000" }}
                  >
                    <div className="icon-text">
                      Target Population:{" "}
                      <strong>PLHIV and Key Populations</strong> Secondary
                      Population: <strong>Health workers.</strong>
                    </div>
                  </Typography>
                </Box>
                <Box></Box>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default Effort;
