import React from "react";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Image from "mui-image";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
// import { isXL } from "common";
import iconImage1 from "assets/images/CountryStories/Dreams/nextGenIcon1.png";
import iconImage2 from "assets/images/CountryStories/Dreams/nextGenIcon2.png";
import iconImage3 from "assets/images/CountryStories/Dreams/nextGenIcon3.png";
import iconImage4 from "assets/images/CountryStories/Dreams/nextGenIcon4.png";
import "../CountryPages.scss";

const NextGen = () => {
  const theme = useSelector(selectapp).theme;
  // const useBody = isXL() ? storyBody2 : storyBody;

  return (
    <Grid2
      container
      id="nextGen"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>DREAMS NextGen</header>
        </Grid2>
        <Grid2 container size={12} sx={storyBody}>
          <Grid2 container size={12} sx={{ paddingBottom: "5vh" }}>
            <Grid2 size={12}>
              <Typography
                variant="h1"
                tabIndex={0}
                sx={{ width: "80vw", textAlign: "center", mx: "auto" }}
              >
                Emphasizes elements that strengthen Adolescent Girls and Young
                Women’s (AGYW) enabling environment to maximize impact and
                sustain results
              </Typography>
            </Grid2>
            <Grid2 container size={12} spacing={4} sx={{ px: "5px" }}>
              <Grid2
                size={3}
                sx={{ border: `3px solid ${theme.dreamsHilite3}` }}
              >
                <Grid2 size={12}>
                  <div className="icon-frame">
                    <Image
                      fit="contain"
                      alt="Decorative image"
                      style={{ width: "10vw" }}
                      src={iconImage1}
                    />
                  </div>
                </Grid2>

                <Grid2 size={12} sx={{ mt: "5vh" }}>
                  <Typography
                    variant="h5"
                    tabIndex={0}
                    sx={{ textAlign: "center", color: theme.dreamsHilite3 }}
                  >
                    &quot;Enabling Activities&quot; to change the environment of
                    AGYW to enable and encourage behavior change
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2
                size={3}
                sx={{ border: `3px solid ${theme.dreamsHilite4}` }}
              >
                <Grid2 size={12}>
                  <div className="icon-frame">
                    <Image
                      fit="contain"
                      alt="Decorative image"
                      style={{ width: "10vw" }}
                      src={iconImage2}
                    />
                  </div>
                </Grid2>

                <Grid2 size={12} sx={{ mt: "5vh" }}>
                  <Typography
                    variant="h5"
                    tabIndex={0}
                    sx={{ textAlign: "center", color: theme.dreamsHilite4 }}
                  >
                    Increase flexibilities to expand some DREAMS approaches at
                    regional/national implementation (ex. partnership with
                    schools and teachers)
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2
                size={3}
                sx={{ border: `3px solid ${theme.dreamsHilite2}` }}
              >
                <Grid2 size={12}>
                  <div className="icon-frame">
                    <Image
                      fit="contain"
                      alt="Decorative image"
                      style={{ width: "10vw" }}
                      src={iconImage3}
                    />
                  </div>
                </Grid2>

                <Grid2 size={12} sx={{ mt: "5vh" }}>
                  <Typography
                    variant="h5"
                    tabIndex={0}
                    sx={{ textAlign: "center", color: theme.dreamsHilite2 }}
                  >
                    Simplify core package of interventions in DREAMS geographies
                    with reduced AGYW incidence to sustain results
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2
                size={3}
                sx={{ border: `3px solid ${theme.dreamsHilite5}` }}
              >
                <Grid2 size={12}>
                  <div className="icon-frame">
                    <Image
                      fit="contain"
                      alt="Decorative image"
                      style={{ width: "10vw" }}
                      src={iconImage4}
                    />
                  </div>
                </Grid2>

                <Grid2 size={12} sx={{ mt: "5vh" }}>
                  <Typography
                    variant="h5"
                    tabIndex={0}
                    sx={{ textAlign: "center", color: theme.dreamsHilite5 }}
                  >
                    Maximize engagement of men and boys in relevant DREAMS
                    activities
                  </Typography>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default NextGen;
