export const storyHeader = {
  height: "7vh",
  // marginTop: 2,
  // border: "1px solid red",
};
export const storyBody2 = {
  height: "70vh",
  width: "90vw",
  overflow: "auto",
  // backgroundColor: "cyan",
};

export const storyBody = {
  height: "80vh",
  width: "90vw",
  overflow: "auto",
  // backgroundColor: "#D9D9D9",
};
