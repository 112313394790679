import React from "react";
import ReactECharts from "echarts-for-react";
import PropTypes from "prop-types";
import { chartColors } from "components/CountryStories/charts";

export const basicBarTemplate = {
  aria: {
    show: false,
  },
  color: chartColors,
  grid: { show: true, top: 10, right: 20, bottom: 100, left: 70 },
  xAxis: {
    type: "category",
    axisTick: {
      alignWithLabel: true,
    },
    axisLabel: {
      show: true,
      padding: 10,
      color: "black",
      fontSize: 10,
      fontWeight: 600,
    },
    data: [],
  },
  yAxis: {
    type: "value",
    axisLabel: {
      show: true,
      padding: 10,
      color: "black",
      fontSize: 10,
      fontWeight: 600,
    },
  },
  series: [
    {
      data: [],
      label: {
        formatter: function (params) {
          return params.value.toLocaleString();
        },
      },
      formatter: function (params) {
        return params.value.toLocaleString();
      },
      type: "bar",
      itemStyle: {
        borderWidth: 2,
        borderRadius: 0,
        borderType: "solid",
        borderColor: "#000000",
        shadowColor: "#5470c6",
      },
      emphasis: {
        itemStyle: {
          // Color in emphasis state.
          color: "green",
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
    extraCssText: "box-shadow: 2px 3px 0px rgba(10, 10, 10, 0.3)",
  },
  center: ["100%", "50%"],
};

const BasicBarChart = (props) => {
  basicBarTemplate.xAxis.data = [...props.labels];
  basicBarTemplate.series[0].data = [...props.data];
  basicBarTemplate.xAxis.axisLabel.rotate = props.rotateLabels
    ? props.rotateLabels
    : 0;

  return (
    <>
      <ReactECharts option={basicBarTemplate} theme="cool" />
    </>
  );
};

BasicBarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.any,
  rotateLabels: PropTypes.number,
};

export default BasicBarChart;
