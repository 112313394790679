import React from "react";
import Grid from "@mui/material/Grid2";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Image from "mui-image";
import Typography from "@mui/material/Typography";
import { storyHeader, storyBody } from "components/Layout";
import image1 from "assets/images/CountryStories/CDI/TheApproach.png";
import "../CountryPages.scss";

const TheApproach = () => {
  const theme = useSelector(selectapp).theme;

  return (
    <Grid
      container
      id="theApproach"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title cdi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>What is Community-Led Monitoring?</header>
        </Grid>
        <Grid container size={12} sx={storyBody}>
          <Grid size={{ sm: 12, md: 6, lg: 6, xl: 6, ultra: 7 }}>
            <Typography tabIndex={0} variant="h2">
              Core Principles
            </Typography>
            <List
              sx={{
                pl: 5,
                lineHeight: "1.2",
                color: theme.color,
                listStyleType: "none",
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem tabIndex={0}>
                <Typography variant="body2">
                  <strong>CLM</strong> is independent from donors and from
                  national governments.
                </Typography>
              </ListItem>
              <ListItem tabIndex={0}>
                <Typography variant="body2">
                  <strong>CLM</strong> is built by communities, from identifying
                  priority indicators to preparing questions and defining
                  preferred channels of communications, from monitoring to
                  owning and housing the data.
                </Typography>
              </ListItem>
              <ListItem tabIndex={0}>
                <Typography variant="body2">
                  <strong>CLM</strong> is led by directly impacted communities,
                  such as people living with HIV, TB or malaria and people from
                  key populations.
                </Typography>
              </ListItem>
              <ListItem tabIndex={0}>
                <Typography variant="body2">
                  <strong>CLM</strong> includes advocacy activities with the aim
                  of generating political will, while focusing on advancing
                  equity and accountability. <br />
                  Source: UNAIDS. Community-led monitoring in action: Emerging
                  evidence and good practice. 2023.
                </Typography>
              </ListItem>
            </List>
            <Typography tabIndex={0} variant="h2">
              PEPFAR Côte d’Ivoire’s COP23 Commitment
            </Typography>
            <List
              sx={{
                pl: 5,
                lineHeight: "1.2",
                color: theme.color,
                listStyleType: "none",
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem tabIndex={0}>
                <Typography variant="body2">
                  <strong>CLM</strong> will provide CSOs with routine,
                  systematic data to direct evidence-based advocacy to improve
                  access and quality of services, accountability, and
                  governance.
                </Typography>
              </ListItem>
              <ListItem tabIndex={0}>
                <Typography variant="body2">
                  <strong>CLM</strong> will also serve as a mechanism to elevate
                  the voices of communities affected by HIV to hold the
                  Government of Côte d’Ivoire, PEPFAR, and others accountable
                  for their programming while strengthening evidence-based
                  advocacy and capacity of CSOs. 
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid size={{ sm: 12, md: 6, lg: 6, xl: 6, ultra: 5 }}>
            <div className="image-frame">
              <Image
                tabIndex={0}
                src={image1}
                style={{ height: "75vh" }}
                fit="contain"
                alt="Figure visualizes the CLM's 6-step approach along a circular cycle: 1. identification of issues/needs, 2. data collection, 3. analysis and interpretation, 4. dissemination, 5. advocacy for the plan/strategy, and 6. monitoring for change. After Step 6. monitoring for change, then the cycle returns to start at Step 2. data collection - where the process continues for another round until the issue/needs have been addressed."
              />
            </div>
          </Grid>
        </Grid>
      </article>
    </Grid>
  );
};

export default TheApproach;
