import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { ResponsiveContainer } from "recharts";
import CountUp from "react-countup";
import PrimaryDataChart from "./PrimaryDataChart";
import ovcBackupChartData from "assets/data-sets/kpi-bar-charts/OVC.json";
import txCurrBackupChartData from "assets/data-sets/kpi-bar-charts/TXCURR.json";
import vmmcBackupChartData from "assets/data-sets/kpi-bar-charts/VMMC.json";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectapp } from "stateMgmt/appSlice";
import Typography from "@mui/material/Typography";
// import logger from "services/LoggingService";

import "./PrimaryDataFigure.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const createChartParams = (jsonData) => {
  const labels = [];
  const data = [];

  const key =
    jsonData.length > 0
      ? Object.keys(jsonData[0]).filter((key) => {
          return key.indexOf("FY") >= 0;
        })[0]
      : null;

  jsonData.forEach((val) => {
    const label = val["Operating Unit"];
    const value = val[key] ? val[key] : 0;
    labels.push(label);
    data.push(value);
  });

  const chartData = { labels, data };
  return chartData;
};

const PrimaryDataFigure = (props) => {
  const value = props.value;
  const previousYearValue = props.previousYearValue;
  const currentYearDescription = props.description;
  const previousYearDescription = props.previousYearDescription;
  const chartTitle = props.chartTitle;
  const chartSubTitle = props.chartSubTitle;

  const theme = useSelector(selectapp).theme;
  // const appContext = useSelector(selectapp).context;
  const [showModal, setShowModal] = useState(false);
  const [displayDesciption, updateDisplayDesciption] = useState(
    previousYearDescription,
  );

  useEffect(() => {}, []);

  const getBackupChartData = () => {
    if (previousYearDescription.includes("Antiretroviral")) {
      return createChartParams(txCurrBackupChartData);
    } else if (previousYearDescription.includes("Circumcisions")) {
      return createChartParams(vmmcBackupChartData);
    } else if (previousYearDescription.includes("Orphans")) {
      return createChartParams(ovcBackupChartData);
    } else {
      return {};
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Dialog
        id="story-modal"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "70vw",
              maxWidth: "70%", // Set your width here
              maxHeight: "100vh",
              padding: "2px",
              margin: "0px",
              overflow: "hidden",
            },
          },
        }}
        aria-label="Primary numbers chart"
        backdrop={"true"}
        role="dialog"
        name="primary-modal"
        aria-modal="true"
        aria-hidden={false}
        tabIndex={"-1"}
        open={showModal}
        onClose={() => setShowModal(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box style={{ width: "100%", margin: "auto" }}>
            <Box sx={{ width: "80%", margin: "auto" }}>
              <Typography
                variant="h1"
                style={{ textAlign: "center" }}
                id="pri-chart-title"
              >
                {chartTitle}
              </Typography>
            </Box>
            <Typography
              variant="h3"
              id="pri-chart-sub-title"
              style={{ textAlign: "center", fontSize: "0.85vw" }}
            >
              {chartSubTitle}
            </Typography>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowModal(false);
          }}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[800],
          })}
        >
          <CloseIcon sx={{ fontSize: "1.5vw" }} />
        </IconButton>
        <DialogContent>
          <div
            className="barchart__div"
            style={{
              backgroundColor: theme.backgroundColor,
              color: theme.color,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              {<PrimaryDataChart chartData={getBackupChartData()} />}
            </ResponsiveContainer>
          </div>
          <Typography variant="h1" id="pri-chart-footer">
            Note: OU level results may not add up to global totals due to
            rounding.
          </Typography>
        </DialogContent>
      </Dialog>
      <div
        id={`cu${props.index}`}
        className="outer__div"
        style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
        tabIndex={1}
        onClick={() => setShowModal(true)}
        role="note"
      >
        <strong>
          <CountUp
            start={previousYearValue}
            delay={3}
            duration={1}
            separator={","}
            end={value}
            onEnd={() => updateDisplayDesciption(currentYearDescription)}
          />
        </strong>
        <div
          style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
        >
          <Typography
            sx={{ py: 0, px: 2 }}
            style={{ fontSize: "0.9rem", fontWeight: 700 }}
          >
            {displayDesciption}
          </Typography>
        </div>
      </div>
    </div>
  );
};

PrimaryDataFigure.propTypes = {
  index: PropTypes.number,
  value: PropTypes.number,
  previousYearValue: PropTypes.number,
  description: PropTypes.string,
  previousYearDescription: PropTypes.string,
  chartTitle: PropTypes.string,
  chartSubTitle: PropTypes.string,
  showModal: PropTypes.bool,
  chartDataPath: PropTypes.string,
};

export default PrimaryDataFigure;
