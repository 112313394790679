import React, { memo, useState, useEffect } from "react";
import { Navigation, Pagination, A11y, EffectFade, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ally from "ally.js";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/effect-fade";
import logger from "services/LoggingService";
import { useSelector } from "react-redux";
import { selectapp } from "stateMgmt/appSlice";
import { selectswiper } from "stateMgmt/swiperSlice";
import { v4 as uuid } from "uuid";

/// ////////////////////// Story Pages /////////////////////////
// *************************************************************

// ------------------------------------------------------------
// DREAMS
// ------------------------------------------------------------
import Empowered from "./pages/Dreams/Empowered";
// import Prevention from "./pages/Dreams/Prevention";
import NextGen from "./pages/Dreams/NextGen";
import Results from "./pages/Dreams/Results";
import Contribution from "./pages/Dreams/Contribution";

// ------------------------------------------------------------
// Cote-diovoire
// ------------------------------------------------------------
import CivilSociety from "./pages/CDI/CivilSociety";
import Timeline from "./pages/CDI/Timeline";
import TheApproach from "./pages/CDI/TheApproach";
import TheHeartOne from "./pages/CDI/TheHeartOne";
import Effort from "./pages/CDI/Effort";
import InAction from "./pages/CDI/InAction";
import Leading from "./pages/CDI/Leading";

// ------------------------------------------------------------
// Malawi
// ------------------------------------------------------------
import CommunityLed from "./pages/Malawi/CommunityLed";
import Implementation from "./pages/Malawi/Implementation";
import TheChallenge from "./pages/Malawi/TheChallenge";
import Remediation from "./pages/Malawi/Remediation";
import RemediationTwo from "./pages/Malawi/RemediationTwo";
import WaitingTime from "./pages/Malawi/WaitingTime";

// ------------------------------------------------------------
// VietNam
// ------------------------------------------------------------
import Engagement from "./pages/VietNam/Engagement";
import Communities from "./pages/VietNam/Communities";
import Access from "./pages/VietNam/Access";
import Clinics from "./pages/VietNam/Clinics";
import Feedback from "./pages/VietNam/Feedback";
// import ContributionsOne from "./pages/VietNam/ContributionsOne";
import ContributionsTwo from "./pages/VietNam/ContributionsTwo";
import RealTimeOne from "./pages/VietNam/RealTimeOne";
import RealTimeTwo from "./pages/VietNam/RealTimeTwo";

/// ////////////////////////////////////////////////////////////

let currentPage;

export const slides = [
  [
    Empowered,
    // Prevention,
    NextGen,
    Results,
    Contribution,
  ], // DREAMS
  [CivilSociety, Timeline, TheApproach, TheHeartOne, Effort, InAction, Leading], // CDI
  [
    CommunityLed,
    Implementation,
    TheChallenge,
    Remediation,
    RemediationTwo,
    WaitingTime,
  ], // Malawi
  [
    Engagement,
    Communities,
    Access,
    Clinics,
    Feedback,
    // ContributionsOne,
    ContributionsTwo,
    RealTimeOne,
    RealTimeTwo,
  ], // Vietnam
];

// ********************** CountryModal Component **********************
const CountryModal = memo(function CountryModal(props) {
  const theme = useSelector(selectapp).theme;
  const currentStoryNumber = useSelector(selectswiper).currentStoryNumber;
  const currentSlides =
    currentStoryNumber !== undefined ? [...slides[currentStoryNumber]] : [];
  const numSlides = currentSlides.length;
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const tabbableElements = ally.query.tabbable({
    context: ".CountryContainer",
    includeContext: true,
    strategy: "quick",
  });

  const pageHeaders = tabbableElements.filter((el) => {
    return el.className === "story-title" || el.className === "divider";
  });

  const setNextTab = () => {
    const direction =
      controlledSwiper.realIndex > controlledSwiper.previousRealIndex
        ? "forward"
        : "reverse";

    currentPage = direction === "forward" ? currentPage + 1 : currentPage - 1;
    if (currentPage === numSlides) {
      currentPage = numSlides - 1;
    } else if (currentPage < 0) {
      currentPage = 0;
    }

    const nextHeader = pageHeaders[currentPage];

    try {
      ally.element.focus(nextHeader);
    } catch {
      logger.error("FOCUS FAILED");
    }
  };

  useEffect(() => {
    currentPage = 0;
  });

  return (
    <div
      className="CountryContainer"
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.color,
      }}
    >
      <Swiper
        style={{
          "--swiper-navigation-color": "black",
          "--swiper-navigation-size": "35px",
        }}
        modules={[Navigation, Pagination, A11y, EffectFade, Keyboard]}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={false}
        // speed={700}
        slidesPerView={1}
        keyboard={{
          enabled: true,
        }}
        mousewheel={{
          enabled: false,
        }}
        zoom={false}
        allowTouchMove={true}
        simulateTouch={false}
        navigation
        pagination={{ type: "progressbar", clickable: true }}
        // grabCursor={true}
        onSwiper={setControlledSwiper}
        onSlideChange={() => {
          setNextTab();
        }}
        onReachEnd={() => {}}
      >
        {currentSlides.map((slideContent, index) => (
          <SwiperSlide key={uuid()} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});
// ********************************************************************

export default CountryModal;
