import React, { useEffect } from "react";
import Grid2 from "@mui/material/Grid2";
import { chartColors } from "components/CountryStories/charts";
import * as echarts from "echarts";
import { selectapp } from "stateMgmt/appSlice";
import { useSelector } from "react-redux";
import { storyHeader, storyBody } from "components/Layout";
import { roundNumber, getChartTitleSize, getChartFontSize } from "common";

import "../CountryPages.scss";
import { Typography } from "@mui/material";

const WaitingTime = () => {
  const theme = useSelector(selectapp).theme;
  let theChart = null;

  const chartOptions = {
    title: {
      text: "Change in Waiting Time at Mikolongewe Health Centre",
      subtext: "",
      left: "center",
      textStyle: {
        fontSize: getChartTitleSize(),
        color: theme.color,
      },
      subtextStyle: {
        color: theme.color,
      },
    },
    aria: {
      show: true,
      label: {
        description: "Change in Waiting Time at Mikolongewe Health Centre.",
      },
    },
    color: chartColors,
    grid: { show: true, containLabel: true },
    xAxis: {
      name: "QUARTER",
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        padding: 10,
        color: "black",
        fontSize: getChartFontSize(),
        fontWeight: 600,
        rotate: 0,
      },
      data: ["Q1 (Oct-Dec 23)", "Q3 (Apr-Jun 24)", "Q4 (Jul-Sep 24)"],
      nameTextStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontWeight: "bolder",
        align: "center",
        padding: [50, 0, 0, 0],
      },
      nameLocation: "middle",
    },
    yAxis: {
      name: "TIME",
      type: "value",
      axisLabel: {
        show: true,
        padding: 10,
        color: "black",
        fontSize: getChartTitleSize(),
        fontWeight: 600,
        formatter: function (params) {
          return roundNumber(params, 2).toString().replace(".", ":");
        },
      },
      nameTextStyle: {
        color: theme.color,
        fontSize: getChartTitleSize(),
        fontWeight: "bolder",
        align: "center",
        padding: [0, 0, 60, 0],
      },
      nameLocation: "middle",
    },
    series: [
      {
        data: [1.59, 1.15, 0.47],
        label: {
          show: true,
          position: "top",
          fontWeight: "bold",
          fontSize: getChartFontSize(),
          formatter: function (params) {
            return params.value.toString().replace(".", ":");
          },
        },
        type: "bar",
        barWidth: "20%",
        itemStyle: {
          color: "#07607F",
          borderWidth: 2,
          borderRadius: 0,
          borderType: "solid",
          borderColor: "#000000",
          shadowColor: "#5470c6",
        },
        emphasis: {
          itemStyle: {
            color: "green",
          },
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      extraCssText: "box-shadow: 4px 6px 0px rgba(10, 10, 10, 0.3)",
    },
    center: ["100%", "50%"],
  };

  function initChart() {
    theChart = echarts.init(document.getElementById("theChart"));
    theChart.setOption(chartOptions);
  }

  useEffect(() => {
    initChart();
  });

  return (
    <Grid2
      container
      id="waitingTime"
      role="presentation"
      spacing={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      size="grow"
    >
      <article>
        <Grid2
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={12}
          className="story-title malawi-title"
          sx={storyHeader}
        >
          <header tabIndex={0}>The Outcome</header>
        </Grid2>
        <Grid2 size={12} sx={storyBody}>
          <Grid2 sx={{ height: "60vh" }} container size={12}>
            <div
              role="presentation"
              tabIndex={0}
              aria-label="interactive chart shows change in Waiting Time at Mikolongewe Health Centre."
              id="theChart"
              className="image-frame"
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography
              sx={{ my: 2 }}
              tabIndex={0}
              variant="body2"
              style={{ textAlign: "center" }}
            >
              Steady decrease in waiting time from almost two hours in FY24 Q1
              to 47 minutes in FY24 Q4,
              <br />
              following deployment of HIV Diagnostic Assistant (HDA) and
              Treatment Supporter.
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography
              sx={{ my: 2 }}
              tabIndex={0}
              variant="body2"
              style={{ textAlign: "center", fontWeight: 700 }}
            >
              Note: Data collection was not done in Q2
            </Typography>
          </Grid2>
        </Grid2>
      </article>
    </Grid2>
  );
};

export default WaitingTime;
